import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionActionEnum } from 'src/app/modules/widget/enums/subscription-action.enum';
import { SubscriptionContainerState } from 'src/app/modules/widget/states/subscription-container.state';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionContainerFacade {
  constructor(private readonly state: SubscriptionContainerState) {}

  /** open a new subscription modal */
  public openNewSubscriptionModal = this.state.openNewSubscriptionModal.bind(
    this.state
  );
  /** open a modify subscription modal */
  public openModifySubscriptionModal = this.state.openModifySubscriptionModal.bind(
    this.state
  );
  /** set subscription container view */
  public setSubscriptionContainerView =
    this.state.setSubscriptionContainerView.bind(this.state);
  /** subscription form type */
  public getFormType = this.state.getFormType.bind(this.state);
  public renewalFlow = this.state.setRenewalFlow.bind(this.state);
  public setStepIndex = this.state.setStepIndex.bind(this.state);
  public stepIndex = this.state.stepIndex$.pipe(distinctUntilChanged());
  public isRenewalFlow = this.state.isRenewalFlow$.pipe(distinctUntilChanged());

  public get getRenewalHeaderText(): string {
    switch (this.getFormType()) {
      case SubscriptionActionEnum.New:
        return 'WIDGET.RENEWAL_MESSAGE.LABELS.HEADER.NEW';
      case SubscriptionActionEnum.Renew:
        return 'WIDGET.RENEWAL_MESSAGE.LABELS.HEADER.RENEW';
      case SubscriptionActionEnum.Modify:
      default:
        return 'WIDGET.RENEWAL_MESSAGE.LABELS.HEADER.MODIFY';
    }
  }

  /** selected subscription container view */
  public viewSelection$ = this.state.viewSelection$;
  /** new subscription component options state */
  public newSubscriptionOption$ = this.state.newSubscriptionOption$;
  /** modify subscription component options state */
  public modifySubscriptionOption$ = this.state.modifySubscriptionOption$;
}
