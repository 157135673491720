import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PortalAuthHttpClient } from '../http/services/portal-auth.http.client';
import { PortalAuthService } from './portal-auth.service';

const API_URL = `${environment.portalBackendEndpoint}uiStorage`;

@Injectable()
export class UiStorageService {
  constructor(
    private readonly http: PortalAuthHttpClient,
    private readonly portalAuthService: PortalAuthService
  ) {}

  get<T extends object>(key: string): Observable<T | null> {
    return this.request(() => {
      const httpParams = this.createHttpParams(key);
      return this.http.get<T>(API_URL, { params: httpParams });
    });
  }

  put<T extends object>(key: string, data: T): Observable<void> {
    return this.request(() => {
      const httpParams = this.createHttpParams(key);
      return this.http.put<void>(API_URL, data, { params: httpParams });
    });
  }

  private createHttpParams(key: string): HttpParams {
    let httpParams = new HttpParams();
    if (!key) {
      throw new Error('Key is required');
    }
    httpParams = httpParams.set('key', key);
    return httpParams;
  }

  private request<T>(func: () => Observable<T>): Observable<T> {
    return this.portalAuthService.isLoggedIn$.pipe(
      take(1),
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(null);
        }
        return func().pipe(take(1));
      })
    );
  }
}
