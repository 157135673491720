import {EditionEnum} from 'app/modules/widget/enums/edition.enum';
import {BillingFrequencyEnum} from 'app/modules/widget/enums/billing-frequency.enum';
import {SuccessPlanEnum} from 'app/modules/widget/enums/success-plan.enum';

export class SortUtility {

  /**
   * sort editions
   * @param editions enums to be sorted
   */
  public static sortEditions(editions: EditionEnum[]): EditionEnum[] {
    editions = Array.from(new Set(editions));
    return editions.sort(
      (a, b) =>
        this.getEditionOrder(a) - this.getEditionOrder(b));
  }

  /**
   * get enum order
   * @param edition enum
   */
  public static getEditionOrder(edition: EditionEnum): number {
    return Object.keys(EditionEnum).indexOf(edition);
  }

  /**
   * sort success plans
   * @param successPlans plans for sorting
   */
  public static sortSuccessPlans(successPlans: SuccessPlanEnum[]): SuccessPlanEnum[] {
    successPlans = Array.from(new Set(successPlans));
    return successPlans.sort(
      (a, b) =>
        this.getSuccessPlanOrder(a) - this.getSuccessPlanOrder(b));
  }

  /**
   * get success plan order
   * @param successPlan plan enum
   */
  public static getSuccessPlanOrder(successPlan: SuccessPlanEnum): number {
    return Object.keys(SuccessPlanEnum).indexOf(successPlan);
  }


  /**
   * sort billing frequencies
   * @param billingFrequencies frequencies for sorting
   */
  public static sortBillingFrequencies(billingFrequencies: BillingFrequencyEnum[]): BillingFrequencyEnum[] {
    billingFrequencies = Array.from(new Set(billingFrequencies));
    return billingFrequencies.sort(
      (a, b) =>
        this.getBillingFrequencyOrder(a) - this.getBillingFrequencyOrder(b));
  }

  /**
   * get billing frequency order
   * @param billingFrequency frequency enum
   */
  public static getBillingFrequencyOrder(billingFrequency: BillingFrequencyEnum): number {
    return Object.keys(BillingFrequencyEnum).indexOf(billingFrequency);
  }
}
