import {JsonProperty} from 'json-object-mapper';

export class Family {
  /** family code */
  @JsonProperty() public code: string;
  /** family title */
  @JsonProperty() public title: string;

  constructor() {
    this.code  = null;
    this.title = null;
  }
}
