import { JsonProperty } from 'json-object-mapper';

import { Address } from 'libs/tsi/customer/src/lib/models/address.model';

export class PrimaryContact {
  /** holds customer name */
  @JsonProperty() public name: {
    title?: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
  };
  /**  holds customer email */
  @JsonProperty() public email: string;
  /**  holds customer phone */
  @JsonProperty() public phone: string;
  /** holds customer billing address */
  @JsonProperty({ type: Address }) public billingAddress?: Address;

  constructor() {
    this.name = null;
    this.email = null;
    this.phone = null;
    this.billingAddress = null;
  }
}
