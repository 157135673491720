import { HttpBackend } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES, LOCAL_TRANSLATIONS_PATH } from '../constants';
import { LanguageData, TranslationResource } from '../models';
import { MultiTranslateHttpLoader } from '../services';

interface TranslationsLoadResult {
  code: string;
  values: Object;
}

function createResource(
  data: LanguageData,
  item: TranslationResource,
  translationsPath: string,
  version: string
): TranslationResource {
  const prefix = `${translationsPath}${item.prefix.replace('{{lang}}', data.code)}`;
  const suffix = `${item.suffix}?v${version}`;
  return { ...item, prefix, suffix };
}

async function loadTranslation(
  httpBackend: HttpBackend,
  data: LanguageData,
  translations: TranslationResource[],
  translationsPath: string,
  version: string
): Promise<TranslationsLoadResult> {
  const resources: TranslationResource[] = translations.map(
    (item: TranslationResource) => createResource(data, item, translationsPath, version)
  );
  const translateLoader = new MultiTranslateHttpLoader(httpBackend, resources);
  const values = await translateLoader.getTranslation(data.code).toPromise();
  return { code: data.code, values };
}

export async function loadAndApplyTranslations(
  translateService: TranslateService,
  httpBackend: HttpBackend,
  translationsPath: string,
  version: string,
  translations: TranslationResource[],
): Promise<void> {
  const promises: Array<Promise<TranslationsLoadResult>> = [];
  
  LANGUAGES.forEach((data: LanguageData) => {
    const loadPromise = new Promise<TranslationsLoadResult>(async (resolve, reject) => {
      let result: TranslationsLoadResult | null = null;
      try {
        result = await loadTranslation(httpBackend, data, translations, translationsPath, version);
      } catch (error) {
        if (translationsPath !== LOCAL_TRANSLATIONS_PATH) {
          console.warn(`Failed to load translations for ${data.code} from ${translationsPath}, attempting local fallback`);
          result = await loadTranslation(httpBackend, data, translations, LOCAL_TRANSLATIONS_PATH, version);
        } else {
          reject(error);
        }
      }
      resolve(result);
    });
    promises.push(loadPromise);
  });

  const results: Array<{ status: string; value?: TranslationsLoadResult; reason?: unknown }> = [];
  await Promise.all(promises.map(p => p.then(
    value => results.push({ status: 'fulfilled', value }),
    reason => results.push({ status: 'rejected', reason })
  )));

  results.forEach((result: { status: string; value?: TranslationsLoadResult; reason?: unknown }) => {
    if (result.status === 'fulfilled' && result.value) {
      translateService.setTranslation(result.value.code, result.value.values, false);
    }
  });
}
