import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionActionEnum } from 'src/app/modules/widget/enums/subscription-action.enum';
import { SubscriptionContainerEnum } from 'src/app/modules/widget/enums/subscription-container.enum';
import { NewSubscriptionOption } from 'src/app/modules/widget/models/new-subscription-option.model';
import { ModifySubscriptionOption } from 'src/app/modules/widget/models/modify-subscription-option.model';
import { Subscription } from 'src/app/modules/widget/models/subscription.model';

@Injectable({ providedIn: 'root' })
export class SubscriptionContainerState {
  private isRenewalFlow = new BehaviorSubject<boolean>(true);
  private stepIndex = new BehaviorSubject<number>(0);
  private newSubscriptionOption = new BehaviorSubject<NewSubscriptionOption>(
    null
  );
  private modifySubscriptionOption = new BehaviorSubject<ModifySubscriptionOption>(
    null
  );
  private viewSelection = new BehaviorSubject<SubscriptionContainerEnum>(
    SubscriptionContainerEnum.SubscriptionListView
  );
  public readonly newSubscriptionOption$ = this.newSubscriptionOption
    .asObservable()
    .pipe(distinctUntilChanged());
  public readonly modifySubscriptionOption$ = this.modifySubscriptionOption
    .asObservable()
    .pipe(distinctUntilChanged());
  public readonly viewSelection$ = this.viewSelection
    .asObservable()
    .pipe(distinctUntilChanged());
  public readonly isRenewalFlow$ = this.isRenewalFlow
    .asObservable()
    .pipe(distinctUntilChanged());

  public readonly stepIndex$ = this.stepIndex
    .asObservable()
    .pipe(distinctUntilChanged());

  /** returns the form type being used in flow */
  public getFormType(): SubscriptionActionEnum {
    return this.newSubscriptionOption?.value?.formType;
  }
  /**
   * new subscription form modal
   */
  public openNewSubscriptionModal(
    formType: SubscriptionActionEnum,
    subscription?: Subscription,
    renewalMessage = ''
  ): void {
    let componentSub: Subscription | undefined;
    let parentSubscription: Subscription | undefined;

    switch (formType) {
      case SubscriptionActionEnum.Renew:
        componentSub = subscription?.draftRenewalSubscription;
        parentSubscription = subscription;
        break;
      case SubscriptionActionEnum.Modify:
      case SubscriptionActionEnum.Upgrade:
        componentSub = subscription;
        break;
    }

    this.newSubscriptionOption.next({
      formType,
      subscription: componentSub,
      parentSubscription,
      renewalMessage,
    });
    console.log(`Opening subscription with id ${componentSub?.id}, parrent subscription id ${parentSubscription?.id}` +
      ` and parent subscription opportunity: ${parentSubscription?.renewalOpportunity?.id}`);
    this.setSubscriptionContainerView(
      SubscriptionContainerEnum.NewSubscriptionView
    );
  }

  public openModifySubscriptionModal(subscription: Subscription): void {
    this.modifySubscriptionOption.next({
      subscription,
    });
    this.setSubscriptionContainerView(
      SubscriptionContainerEnum.ModifySubscriptionView
    );
  }

  /** Set view shown on subscription container */
  public setSubscriptionContainerView(view: SubscriptionContainerEnum): void {
    this.viewSelection.next(view);
  }

  public setRenewalFlow(value: boolean): void {
    this.isRenewalFlow.next(value);
  }

  public setStepIndex(value: number): void {
    this.stepIndex.next(value);
  }
}
