import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserProperties } from 'app/modules/portal/portal-container/models/user-properties.model';
import { CUSTOMER_ID_KEY, INTERNAL_CUSTOMER_ID_KEY, PRODUCT_VARIANT_TITLE } from 'app/modules/widget/config/local-storage.config';
import { IncludedItem } from 'app/modules/widget/models/included-item.model';
import { Subscription } from 'app/modules/widget/models/subscription.model';
import { VoiceflowService } from 'app/modules/widget/services/voiceflow.service';
import { HelperMethods } from 'app/modules/widget/utils/helper-methods';
import { ObjectMapper } from 'json-object-mapper';
import Mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

const BUTTON_CLICK_EVENT = 'Button Click';
const MISSING_TRANSLATION_EVENT = 'Missing Translation';
const PAGE_VIEW_EVENT = 'Page View';
const PRODUCT_INTERESTS_EVENT = 'Product Interests';
const TOKEN_KEY = 'token';
const UNMUTE_EVENT = 'Video Unmute';
const VIDEO_WATCHED_EVENT = 'Video Watched';

export interface MixpanelParams {
  customerEntityId?: string;
  customerInternalId?: string;
  productFamilyCode: string;
  productVariantCode: string;
  subscriptionParentId: string;
  subscriptionChildId: string;
  subscriptionName: string;
  customerName: string;
  endUserName: string;
}

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private jwtHelper = new JwtHelperService();
  private userProperties = new UserProperties();

  constructor(private readonly _voiceFlowService: VoiceflowService) {
    this.setUserProperties(localStorage.getItem(TOKEN_KEY));
    this.initializeMixpanel();
  }

  private initializeMixpanel(): void {
    if (this.isUserAdmin()) {
      return;
    }

    Mixpanel.init(environment.mixpanelToken, {
      debug: !environment.production,
    });
  }

  private isUserAdmin() {
    return HelperMethods.isUserAdmin(this.userProperties);
  }

  public trackEvent(event: string, properties?: any): void {
    // Check admin status; if admin, skip tracking
    if (this.isUserAdmin()) {
      return;
    }
    // Ensure Mixpanel is initialized before tracking
    if (!Mixpanel.has_opted_out_tracking()) {
      Mixpanel.track(event, properties);
    }
  }

  private sanitizeUrl(url: string): string {
    const urlObj = new URL(url);
    urlObj.searchParams.delete('jwt'); // Delete the JWT token from the URL parameters
    return urlObj.toString();
  }

  private setUserProperties(token?: string): void {
    if (!token) {
      console.log('No token found');
      return;
    }
    try {
      this.userProperties = ObjectMapper.deserialize(
        UserProperties,
        this.jwtHelper.decodeToken(token)
      );
    } catch (error) {
      console.error('Error setting user properties', error);
    }
  }

  identifyUser(): void {
    if (!this.isUserAdmin() && this.userProperties?.email) {
      Mixpanel.identify(this.userProperties?.email);

      // Set user profile properties
      this.setUserProfileProperties();
    }
  }

  private setUserProfileProperties(): void {
    Mixpanel.people.set({
      $email: this.userProperties?.email,
      $name: this.userProperties?.email,
    });
  }

  private getCommonProperties(params?: MixpanelParams): Object {
    const sanitizedUrl = this.sanitizeUrl(window.location.href);
    return {
      $current_url: sanitizedUrl,
      customer_entity_id: params?.customerEntityId || localStorage.getItem(CUSTOMER_ID_KEY),
      customer_internal_id: params?.customerInternalId || localStorage.getItem(INTERNAL_CUSTOMER_ID_KEY),
      product_family_code: params?.productFamilyCode,
      product_variant_code: params?.productVariantCode,
      subscription_parent_id: params?.subscriptionParentId,
      subscription_child_id: params?.subscriptionChildId,
      subscription_name: params?.subscriptionName,
      customer_name: params?.customerName,
      end_user_name: params?.endUserName,
    };
  }

  trackPageView(
    pageName: string, params?: MixpanelParams, subscriptionId?: string, subscriptions?: Subscription[]
  ): void {
    this.trackEvent(PAGE_VIEW_EVENT, {
      ...this.getCommonProperties(params),
      page: pageName,
    });

    this.setVoiceflowVariables(pageName, subscriptionId, subscriptions);
  }

  trackUnmuteVideoEvent(videoId: string, userId: string, timestamp: string): void {
    this.trackEvent(UNMUTE_EVENT, {
      ...this.getCommonProperties(),
      videoId: videoId.match(/[^/]+$/)[0],
      timestamp: timestamp,
      user_id: userId,
    });
  }

  public setVoiceflowVariables(pageUrl: string, subscriptionId?: string, subscriptions?: Subscription[]) {
    const subId = subscriptions?.length === 1 ? subscriptions[0]?.id.toString() : subscriptionId;
    const productVariantTitle = localStorage.getItem(PRODUCT_VARIANT_TITLE);

    this._voiceFlowService.updateVariables(this.userProperties.email, {
      pageUrl: pageUrl, sub_id: subId ? subId : '', product_variant: productVariantTitle ? productVariantTitle : ''
    });
  }

  trackVideoWatched(
    videoId: string,
    watchedPercentage: number,
    params: MixpanelParams
  ): void {
    this.trackEvent(VIDEO_WATCHED_EVENT, {
      ...this.getCommonProperties(params),
      videoId: videoId.match(/[^/]+$/)[0],
      percentage: watchedPercentage,
    });
  }

  trackButtonClick(buttonId: string, params?: MixpanelParams): void {
    this.trackEvent(BUTTON_CLICK_EVENT, {
      ...this.getCommonProperties(params),
      buttonId: buttonId,
    });
  }

  trackProductInterests(interests: string[], params: MixpanelParams): void {
    this.trackEvent(PRODUCT_INTERESTS_EVENT, {
      ...this.getCommonProperties(params),
      products: interests,
    });
  }

  trackItemIncreaseClick(buttonId: string, items: Partial<IncludedItem>[], params?: MixpanelParams): void {
    this.trackEvent(BUTTON_CLICK_EVENT, {
      ...this.getCommonProperties(params),
      buttonId: buttonId,
      items: items,
    });
  }

  trackMissingTranslation(key: string, language: string): void {
    this.trackEvent(MISSING_TRANSLATION_EVENT, {
      ...this.getCommonProperties(),
      translation_key: key,
      language: language,
    });
  }
}
