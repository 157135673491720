export enum SuccessPlanEnum {
  Standard = 'Standard',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
}

export enum SuccessPlanEnumUI {
  Silver = 'Standard',
  Gold = 'Gold',
  Platinum = 'Platinum',
}
