import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandlerService } from 'app/core/services/custom-error-handler.service';
import { DocumentType } from 'app/modules/widget/enums/document-type.enum';
import { SubscriptionContainerEnum } from 'app/modules/widget/enums/subscription-container.enum';
import { NotificationFacade } from 'app/modules/widget/facades/notification.facade';
import { StepperFacade } from 'app/modules/widget/facades/stepper.facade';
import { SubscriptionContainerFacade } from 'app/modules/widget/facades/subscription-container.facade';
import { DocumentTypeModel } from 'app/modules/widget/models/document-type.model';
import { FileListItems } from 'app/modules/widget/models/fileList.model';
import { Subscription } from 'app/modules/widget/models/subscription.model';
import { combineLatest, Subject } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { SubscriptionFacade } from '../../facades/subscription.facade';

@Component({
  selector: 'app-documents-modal',
  templateUrl: 'documents-modal.component.html',
  styleUrls: ['documents-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsModalComponent implements OnDestroy, OnInit {
  @Input() activeSubscription: Subscription;
  @Input() draftSubscription: Subscription;
  @Input() dropdownMenu: DocumentTypeModel[];
  @Input() isFinalizeMode = false;
  @Input() isInlineUi = false;

  @Output() onBack = new EventEmitter<void>();

  @ViewChild('containerForNotification', { read: ViewContainerRef })
  public notificationContainer: ViewContainerRef;

  hidePo = false;
  infoText: string;
  isFinalizeButtonDisabled = true;
  isFinalizeButtonLoading = false;
  title: string;
  tooltipText: string;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public customErrorHandler: CustomErrorHandlerService,
    public notificationFacade: NotificationFacade,
    public stepperFacade: StepperFacade,
    public subscriptionContainerFacade: SubscriptionContainerFacade,
    public subscriptionFacade: SubscriptionFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService
  ) {}

  get isRenewalFlow(): boolean {
    return !!this.draftSubscription;
  }

  get opportunityId(): number {
    return this.isRenewalFlow
      ? this.activeSubscription.renewalOpportunity.id
      : this.activeSubscription.upsellOpportunity.id;
  }

  get isPORequired(): boolean | null {
    return this.isRenewalFlow
      ? this.activeSubscription.isRenewalPORequired
      : this.activeSubscription.isUpsellPORequired;
  }

  ngOnInit(): void {
    this.assignTitleAndInfoText();
  }

  ngOnDestroy(): void {
    this.activeSubscription = null;
    this.dropdownMenu = null;
    this.draftSubscription = null;
    this.notificationContainer = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRetry(): void {
    this.stepperFacade.documentsLoadingError$.next(false);
    this.stepperFacade.refreshTheDocumentTable$.next(true);
  }

  closeOpportunity(): void {
    this.isFinalizeButtonDisabled = false;
    this.isFinalizeButtonLoading = true;

    this.stepperFacade
      .closeOpportunity(this.opportunityId)
      .pipe(
        take(1),
        mergeMap(() => {
          return combineLatest([
            this.subscriptionFacade
              .subscription(this.activeSubscription.id.toString())
              .pipe(take(1)),
            this.subscriptionFacade.subscriptions$.pipe(take(1)),
          ]);
        }),
        tap(([subscription, subscriptions]) => {
          const subscriptionItem = subscriptions.find(
            (item) => item.id === subscription.id
          );
          if (subscriptionItem) {
            subscriptionItem.quotes = subscription.quotes;
            subscriptionItem.operations = subscription.operations;
            subscriptionItem.renewalOpportunity = subscription.renewalOpportunity;
            subscriptionItem.upsellOpportunity = subscription.upsellOpportunity;
          }
        })
      )
      .subscribe(
        () => {
          this.translate
            .get('WIDGET.DOCUMENTS_MODAL.NOTIFICATIONS.SUCCESS')
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: string) => {
              this.notificationFacade.successNotification(res);
            });
          this.subscriptionContainerFacade.setSubscriptionContainerView(
            SubscriptionContainerEnum.SubscriptionListView
          );
          this.resetState();
        },
        (_) => {
          this.translate
            .get('WIDGET.DOCUMENTS_MODAL.NOTIFICATIONS.ERROR')
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: string) => {
              this.notificationFacade.errorNotification(res);
            });
          this.customErrorHandler.handleError(
            `Opportunity ${this.opportunityId} can't be closed due the error`
          );
          this.resetState();
        }
      );
  }

  resetState(): void {
    this.isFinalizeButtonDisabled = true;
    this.isFinalizeButtonLoading = false;
    this.cdr.markForCheck();
  }

  onFilesEmited($event: FileListItems[]): void {
    // First, check if there is a PO document uploaded
    const poDocumentUploaded = $event.find(
      (file) => file.documentType === DocumentType.PurchaseOrder
    );
    // If PO document is uploaded, enable the button
    // Otherwise, check if PO is required and disable the button if no PO document is uploaded
    this.isFinalizeButtonDisabled = !poDocumentUploaded && this.isPORequired;
    this.cdr.markForCheck();
  }

  back(): void {
    this.onBack.emit();
  }

  private assignTitleAndInfoText(): void {
    let poRequiredMode: string;
    let subscriptionMode: string;

    if (this.isRenewalFlow) {
      subscriptionMode = 'SUBSCRIPTION_RENEWAL';
    } else {
      subscriptionMode = 'SUBSCRIPTION_MODIFY';
    }
    this.hidePo = false;
    if (this.isPORequired === null || !this.isFinalizeMode) {
      poRequiredMode = 'PO_OPTIONAL';
    } else if (this.isPORequired) {
      poRequiredMode = 'PO_REQUIRED';
    } else {
      poRequiredMode = 'PO_NOT_REQUIRED';
      this.dropdownMenu = this.dropdownMenu.filter(
        (document: DocumentTypeModel) => document.value !== 'PO'
      );
      this.hidePo = true;
    }
    this.title = `WIDGET.DOCUMENTS_MODAL.LABELS.TITLE.${poRequiredMode}`;
    this.infoText = `WIDGET.DOCUMENTS_MODAL.LABELS.${subscriptionMode}.${poRequiredMode}.INFO_TEXT`;
    this.tooltipText = `WIDGET.DOCUMENTS_MODAL.LABELS.${subscriptionMode}.TOOLTIP_TEXT`;
  }
}
