import { JsonProperty } from 'json-object-mapper';
import { EditionEnum } from 'src/app/modules/widget/enums/edition.enum';
import { InitialTermTypeEnum } from 'src/app/modules/widget/enums/initial-term-type.enum';
import { SuccessPlanEnum } from 'src/app/modules/widget/enums/success-plan.enum';
import { Initialterm } from 'src/app/modules/widget/models/initial-term.model';
import { Item } from 'src/app/modules/widget/models/item.model';
import { Product } from 'src/app/modules/widget/models/product.model';

export class SubscriptionPlan {
  /** display name */
  @JsonProperty({ name: '_ns_displayname' }) public nsDisplayName: string;
  /** is plan trial */
  @JsonProperty() public isTrial: boolean;
  /** subscription plan code */
  @JsonProperty() public code: string;
  /** subscription title */
  @JsonProperty() public title?: string;
  /** id */
  @JsonProperty() public id: number;
  /** product */
  @JsonProperty() public product: Product;
  /** initial term details */
  @JsonProperty() public initialterm: Initialterm;
  /** plan items */
  @JsonProperty() public items: Item[];
  /** support level */
  @JsonProperty() public supportLevel: SuccessPlanEnum;
  /** product tier */
  @JsonProperty() public productTier: EditionEnum;
  /** product tier */
  @JsonProperty() public productTierTitle: string;
  /** revenue type */
  @JsonProperty() public revenueType: string;
  /** enable subscription items quantity edit */
  @JsonProperty() public enableItemsQuantityEdit?: boolean;

  public get isEvergreen(): boolean {
    return this.initialterm.type === InitialTermTypeEnum.Evergreen;
  }

  constructor() {
    this.nsDisplayName = null;
    this.isTrial = null;
    this.code = null;
    this.title = null;
    this.id = null;
    this.product = null;
    this.initialterm = null;
    this.items = null;
    this.supportLevel = null;
    this.productTier = null;
    this.productTierTitle = null;
    this.revenueType = null;
    this.enableItemsQuantityEdit = null;
  }

  get variantAndFamilyName(): string {
    const familyTitle  = this.product.family.title;
    const variantTitle = this.product.variant.title;

    return variantTitle ? `${familyTitle} ${variantTitle}` : familyTitle;
  }
}
