import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PortalAuthFacade } from 'src/app/core/facades/portal-auth.facade';
import { BaseAuthInterceptor } from 'src/app/core/http/interceptors/base-auth.interceptor';

const SIGNUP_ROUTE = '/signup';

@Injectable()
export class PortalAuthInterceptor
  extends BaseAuthInterceptor
  implements HttpInterceptor
{
  constructor(
    private readonly service: PortalAuthFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return this.service.token$.pipe(
      switchMap((token) => {
        // Check if the token is expired before making the API call
        const queryParams = this.route.snapshot.queryParams;
        if (this.service.isTokenExpired()) {
          this.router.navigate([SIGNUP_ROUTE], { queryParams });
          return this.translate.get("CORE.PORTAL_AUTH.ERRORS.SESSION_EXPIRED").pipe(
            map((res: string) => throwError(res))
          ) as Observable<never>;
        }

        request = this.addToken<T>(request, token);
        return next.handle(request).pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              // Handle other 401 errors
            } else if (error.status === 0) {
              // Handle network errors or refused connections
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
