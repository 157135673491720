import { Injectable } from '@angular/core';
import {AuthHttpClient} from 'app/core/http/services/auth.http.client';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VoiceflowService {
  constructor(private http: AuthHttpClient) {}

  updateVariables(userID: string, variables: any): void {
    const url             = `${environment.apiUrl}voiceflow/updateVariables`;
    const customVariables = {
      userID, variables
    };

    this.http.post(url, customVariables).subscribe();
  }
}
