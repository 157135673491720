import { Injectable } from '@angular/core';
import { PageSample } from 'app/modules/widget/models/prime-content.model';
import { Subscription } from 'app/modules/widget/models/subscription.model';
import { RenewalState } from 'app/modules/widget/states/renewal.state';
import {BehaviorSubject} from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StepperFacade {
  public loader$ = this.renewalState.loader$;
  public error$ = this.renewalState.error$;
  public navigateToFinalizeStep$ = new BehaviorSubject(false);
  public setItems = this.renewalState.setItems.bind(this.renewalState);
  public setQuoteLoader = this.renewalState.setQuoteLoader.bind(
    this.renewalState
  );
  public setLoader = this.renewalState.setLoader.bind(this.renewalState);
  public quoteLoader$ = this.renewalState.quoteLoader$;
  public setError = this.renewalState.setError.bind(this.renewalState);

  public createQuote = this.renewalState.createQuote.bind(this.renewalState);
  public getPreview = this.renewalState.getPreview.bind(this.renewalState);
  public setAwsPrimeAnswer = this.renewalState.setAwsPrimeAnswer.bind(
    this.renewalState
  );
  public setCrossoverPrimeAnswer =
    this.renewalState.setCrossoverPrimeAnswer.bind(this.renewalState);
  public setRenewalComplete = this.renewalState.setRenewalComplete.bind(this.renewalState);
  public setPrice = this.renewalState.setPrice.bind(this.renewalState);
  public setPrimeInterests = this.renewalState.setProductInterests.bind(
    this.renewalState
  );
  public setCustomerInterest = this.renewalState.setCustomerInterest.bind(
    this.renewalState
  );
  public setPlanCode = this.renewalState.setPlanCode.bind(this.renewalState);
  //  public completeRenewal    = this.renewalState.completeRenewal.bind(this.renewalState);
  public setDiscountPercentage = this.renewalState.setDiscountPercentage.bind(
    this.renewalState
  );
  public setTimestamp = this.renewalState.setTimestamp.bind(this.renewalState);
  public renewalIntent = this.renewalState.renewalIntent.bind(
    this.renewalState
  );
  public setPreviewResponse       = this.renewalState.setPreviewResponse.bind(
    this.renewalState
  );
  public refreshTheDocumentTable$ = new BehaviorSubject(false);
  public documentsLoadingError$   = new BehaviorSubject(false);

  public cancelQuote = this.renewalState.cancelQuote.bind(this.renewalState);
  public prepareAndUploadDocuments = this.renewalState.prepareAndUploadFileObservable.bind(this.renewalState);
  public getUploadedDocuments      = this.renewalState.getUploadedDocuments.bind(this.renewalState);
  public deleteDocument            = this.renewalState.deleteDocument.bind(this.renewalState);
  public sendSupportMessage        = this.renewalState.sendSupportMessageObservable.bind(this.renewalState);
  public closeOpportunity = this.renewalState.closeOpportunity.bind(this.renewalState);

  public finalizeErrorMessages$ = this.renewalState.finalizeErrorMessages$;
  constructor(private renewalState: RenewalState) {}

  public get state() {
    return this.renewalState.getState();
  }

  getCurrentStep(
    steps: { label: string }[],
    subscription: Subscription
  ): Observable<number> {
    return this.state.pipe(
      map((state) =>
        this.renewalState.getCurrentStep(state, steps, subscription)
      )
    );
  }

  setSuccessLevel(level: string, opportunity: number) {
    this.renewalState.setSuccessLevel(level, opportunity);
  }

  setPrimeProducts(primeProduct: Partial<PageSample>, opportunity: number) {
    this.renewalState.setPrimeProducts(primeProduct, opportunity);
  }
  removePrimeProduct(
    primeProduct: Partial<PageSample>, opportunity: number
  ) {
    this.renewalState.removePrimeProduct(primeProduct, opportunity);
  }

  setProductEdition(productEdition: string, opportunity: number) {
    this.renewalState.setProductEdition(productEdition, opportunity);
  }

  setCurrentStep(step: number, opportunity: number) {
    this.renewalState.setCurrentStep(step, opportunity);
  }
}
