import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PrimaryContact } from 'app/modules/widget/models/customer.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as RenewalContactActions from './renewal-contact.actions';
import { State } from './renewal-contact.reducer';
import {
  selectLoadSuccess, selectPrimaryContact, selectRenewalContactError, selectRenewalContactLoading, selectUpdateSuccess
} from './renewal-contact.selectors';

@Injectable({
  providedIn: 'root'
})
export class RenewalContactFacade {
  primaryContact$: Observable<any>     = this.store.pipe(select(selectPrimaryContact));
  error$: Observable<any>              = this.store.pipe(select(selectRenewalContactError));
  loading$: Observable<boolean>        = this.store.pipe(select(selectRenewalContactLoading));
  updateSuccess$: Observable<boolean>  = this.store.pipe(select(selectUpdateSuccess));
  loadPrimarySuccess$: Observable<any> = this.store.pipe(select(selectLoadSuccess));

  loadRenewalContactError$: Observable<unknown> = this.actions$.pipe(ofType(RenewalContactActions.loadRenewalContactFailure),
    map((action) => action.error));

  updateRenewalContactError$: Observable<unknown> = this.actions$.pipe(ofType(RenewalContactActions.updateRenewalContactFailure),
    map((action) => action.error));

  constructor(private actions$: Actions, private store: Store<State>) {}

  updateRenewalContact(subscriptionId: number, primaryContact: Partial<PrimaryContact>) {
    this.store.dispatch(RenewalContactActions.updateRenewalContact({subscriptionId, primaryContact}));
  }

  loadRenewalContact(subscriptionId: number) {
    this.store.dispatch(RenewalContactActions.loadRenewalContact({subscriptionId}));
  }
}
