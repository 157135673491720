import {Action, createReducer, on} from '@ngrx/store';
import {PrimaryContact} from 'app/modules/widget/models/customer.model';
import * as RenewalContactActions from './renewal-contact.actions';

export const RENEWAL_CONTACT_FEATURE_KEY = 'renewalContact';

export interface State {
  primaryContact: Partial<PrimaryContact>;
  error: any;
  loading: boolean;
  updateSuccess: boolean;
  loadSuccess: boolean;
}

export const initialState: State = {
  primaryContact: null, error: null, loading: false, updateSuccess: false, loadSuccess: false
};

export const renewalContactReducer = createReducer(initialState,
  on(RenewalContactActions.loadRenewalContact, state => ({
    ...state, updateSuccess: false, loading: true, error: null
  })), on(RenewalContactActions.loadRenewalContactSuccess, (state, {primaryContact}) => ({
    ...state, primaryContact: primaryContact, loading: false, error: null, loadSuccess: true
  })), on(RenewalContactActions.loadRenewalContactFailure, (state, {error}) => ({
    ...state, error, loading: false, loadSuccess: false
  })), on(RenewalContactActions.updateRenewalContact, state => ({
    ...state, loading: true, error: null, updateSuccess: false
  })), on(RenewalContactActions.updateRenewalContactSuccess, (state) => ({
    ...state, loading: false, error: null, updateSuccess: true
  })), on(RenewalContactActions.updateRenewalContactFailure, (state, {error}) => ({
    ...state, error, loading: false, updateSuccess: false
  })));

export function reducer(state: State | undefined, action: Action): State {
  return renewalContactReducer(state, action);
}
