import { Pipe, PipeTransform } from '@angular/core';
import { CURRENCY_FORMAT } from 'app/shared/config/list-of-currencies';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  private currencyFormats = CURRENCY_FORMAT;

  transform(value: number | string, currencyCode = 'USD', format = '1.0-2'): string {
    const num = this.formatNumber(value, format);
    const currencyFormat = this.currencyFormats[currencyCode];

    if (!currencyFormat) {
      return `${currencyCode} ${num}`;
    }

    const symbol = currencyFormat.symbol;
    const space = currencyFormat.whitespace ? ' ' : '';

    if (currencyFormat.position === 'left') {
      return `${symbol}${space}${num}`;
    } else {
      return `${num}${space}${symbol}`;
    }
  }

  private formatNumber(value: number | string, format: string): string {
    // Determine the desired number of decimal places from the format
    const decimalPlaces = parseInt(format.split('.')[1]) || 0;

    // Convert value to fixed decimal places
    let number = Number(value).toFixed(decimalPlaces);

    // Insert commas as thousand separators
    const parts = number.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Reconstruct number with thousand separators and correct decimal places
    number = parts.join('.');

    return number;
  }
}
