import {JsonProperty} from 'json-object-mapper';
import {BillingFrequencyEnum} from 'src/app/modules/widget/enums/billing-frequency.enum';

export class Term {
  /** term end date */
  @JsonProperty() public end: string;
  /** billing frequency */
  @JsonProperty() public frequency: BillingFrequencyEnum;
  /** represents the next  */
  @JsonProperty() public nextBillCycleDate: string;
  /** term start date */
  @JsonProperty() public start: string;

  constructor() {
    this.start             = null;
    this.end               = null;
    this.nextBillCycleDate = null;
    this.frequency         = null;
  }
}
