import { Injectable } from '@angular/core';
import {
  getDuration,
  NON_LEGACY_SUBSCRIPTION_PLAN_REGEX,
  SortUtility,
  SUPPORT_ONLY_SUBSCRIPTION_PLAN_REGEX,
} from '@tsi/shared/util';
import { PortalAuthFacade } from 'app/core/facades/portal-auth.facade';
import { SubscriptionActionEnum } from 'app/modules/widget/enums/subscription-action.enum';
import { SubscriptionPlanGroupEnum } from 'app/modules/widget/enums/subscription-plan-group.enum';
import { TabIndices } from 'app/modules/widget/enums/tab-indices.enum';
import { SubscriptionContainerFacade } from 'app/modules/widget/facades/subscription-container.facade';
import { HelperMethods } from 'app/modules/widget/utils/helper-methods';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subscription as RxjsSubscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { InitializationService } from 'src/app/core/services/initialization.service';
import { BillingFrequencyEnum } from 'src/app/modules/widget/enums/billing-frequency.enum';
import { DurationEnum } from 'src/app/modules/widget/enums/duration.enum';
import { EditionEnum } from 'src/app/modules/widget/enums/edition.enum';
import { StatusEnum } from 'src/app/modules/widget/enums/status.enum';
import { SuccessPlanEnum } from 'src/app/modules/widget/enums/success-plan.enum';
import { SubscriptionConfig } from 'src/app/modules/widget/models/subscription-config.model';
import { SubscriptionPlan } from 'src/app/modules/widget/models/subscription-plan.model';
import { Subscription } from 'src/app/modules/widget/models/subscription.model';
import { SubscriptionService } from 'src/app/modules/widget/services/subscription.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionState {
  private readonly error = new BehaviorSubject<string>(null);
  private readonly isReady = new BehaviorSubject(false);
  private readonly isRetrying = new BehaviorSubject(false);

  private readonly legacySubscriptionPlans = new BehaviorSubject<
    SubscriptionPlan[]
  >([]);
  private readonly newSubscriptionPlans = new BehaviorSubject<
    SubscriptionPlan[]
  >([]);
  private readonly supportOnlySubscriptionPlans = new BehaviorSubject<
    SubscriptionPlan[]
  >([]);

  private readonly groupedSubscriptionPlans = new BehaviorSubject<
    SubscriptionPlan[]
  >([]);
  private readonly subscriptions = new BehaviorSubject<Subscription[]>([]);
  private readonly selectedPlanConfig = new BehaviorSubject<
    SubscriptionConfig | undefined
  >(undefined);
  private readonly editions = new BehaviorSubject<EditionEnum[]>([]);
  private readonly successPlans = new BehaviorSubject<SuccessPlanEnum[]>([]);
  private readonly billingFrequencies = new BehaviorSubject<
    BillingFrequencyEnum[]
  >([]);
  private readonly hasOneMatchingEndUserAndCustomer =
    new BehaviorSubject<boolean>(false);

  /** Whether the state is ready or not to use. */
  public isReady$: Observable<boolean>;
  /** api call subscription */
  private subscription: RxjsSubscription;
  /** api error */
  public error$ = this.error.asObservable().pipe(distinctUntilChanged());

  /** Represents whether endpoint is retrying to fetch the data. */
  public isRetrying$ = this.isRetrying
    .asObservable()
    .pipe(distinctUntilChanged());
  /** subscriptions */
  public subscriptions$ = this.subscriptions
    .asObservable()
    .pipe(distinctUntilChanged());
  /**
   * subscription plans grouped by category
   * legacy, non-legacy and support only
   */
  public groupedSubscriptionPlans$ = this.groupedSubscriptionPlans
    .asObservable()
    .pipe(distinctUntilChanged());
  /** plan configuration */
  public selectedPlanConfig$ = this.selectedPlanConfig
    .asObservable()
    .pipe(distinctUntilChanged());
  /** product editions */
  public editions$ = this.editions.asObservable().pipe(distinctUntilChanged());
  /** success plans */
  public successPlans$ = this.successPlans
    .asObservable()
    .pipe(distinctUntilChanged());
  /** billing frequencies */
  public billingFrequencies$ = this.billingFrequencies
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly initService: InitializationService, private readonly facade: SubscriptionContainerFacade,
    private readonly authFacade: PortalAuthFacade
  ) {
    this.isReady$ = combineLatest([this.isReady, this.isRetrying]).pipe(
      tap(([isReady, retrying]) => {
        if ((!isReady || retrying) && !this.subscription) {
          this.getRecords();
        }
      }),
      map(([isReady]) => isReady)
    );
  }

  /** Reset all states to avoid any memory leak on destruction of component. */
  public reset(): void {
    this.setReadyState(false);
    this.setErrorState(null);
    this.setIsRetryingState(false);
    this.updateRecords([null, []]);
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  /** To be used the re-trigger the endpoint api call */
  public retry(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this.setIsRetryingState(true);
  }

  /**
   * get subscriptions and
   * subscription plans simultaneously
   */
  public getRecords(): void {
    this.subscription = this.getSubscriptions()
      .pipe(
        switchMap((subscriptions) => {
          const tokenCustomerId = this.initService.tokenCustomerId;
          // end user is same as customer or token customer id is same as subscription customer
          const matchingSub = subscriptions.find(
            (subscription) =>
              subscription.customer.id === tokenCustomerId ||
              subscription.customer.id === subscription.endUser.id
          );
          this.hasOneMatchingEndUserAndCustomer.next(!!matchingSub);
          return forkJoin([of(subscriptions), this.getSubscriptionPlans()]);
        })
      )
      .pipe(take(1))
      .subscribe(
        (data) => this.onSuccess(data),
        (error) => this.onError(error.message)
      );
  }

  /** error handling */
  public onError(error: string): void {
    this.setReadyState(true);
    this.setErrorState(error);
    this.setIsRetryingState(false);
    this.updateRecords([null, null]);
  }

  /** handle successful api call */
  private onSuccess(data: [Subscription[], SubscriptionPlan[]]): void {
    this.setReadyState(true);
    this.setIsRetryingState(false);
    this.setErrorState(null);
    this.updateRecords(data);
  }

  private setErrorState(value: string): void {
    this.error.next(value);
  }

  private setIsRetryingState(value: boolean): void {
    this.isRetrying.next(value);
  }

  private setReadyState(value: boolean): void {
    this.isReady.next(value);
  }

  private updateRecords(data: [Subscription[], SubscriptionPlan[]]): void {
    this.subscriptions.next(data[0]);
    // legacy subscription plans
    this.legacySubscriptionPlans.next(
      data[1].filter(
        (plan) => !NON_LEGACY_SUBSCRIPTION_PLAN_REGEX.test(plan.nsDisplayName)
      )
    );
    // is non-legacy subscription plan but not support only
    this.newSubscriptionPlans.next(
      data[1].filter(
        (plan) =>
          NON_LEGACY_SUBSCRIPTION_PLAN_REGEX.test(plan.nsDisplayName) &&
          !SUPPORT_ONLY_SUBSCRIPTION_PLAN_REGEX.test(plan.nsDisplayName)
      )
    );
    // is support only subscription plan but not support only
    this.supportOnlySubscriptionPlans.next(
      data[1].filter((plan) =>
        SUPPORT_ONLY_SUBSCRIPTION_PLAN_REGEX.test(plan.nsDisplayName)
      )
    );
    this.groupedSubscriptionPlans.next([
      ...this.legacySubscriptionPlans.value,
      ...this.newSubscriptionPlans.value,
    ]);
  }

  /**
   * set parameter for default subscription plan.
   * applicable to new subscription or upgrade
   */
  private getDefaultPlanConfig(): SubscriptionConfig {
    if (this.newSubscriptionPlans.value.length) {
      this.groupedSubscriptionPlans.next(this.newSubscriptionPlans.value);
    } else {
      this.groupedSubscriptionPlans.next(this.legacySubscriptionPlans.value);
    }
    const edition = this.getEditions()?.[0];
    const successPlan = this.getSuccessPlans(edition)?.[0];
    const billingFrequency = this.getBillingFrequencies({
      edition,
      successPlan,
    })?.[0];
    const duration = DurationEnum.OneYear;
    return { edition, successPlan, billingFrequency, duration };
  }

  /**
   * set radio buttons when existing plan
   * available
   * @param subscription customer subscription
   * @param setConfigOptions update radio buttons
   * @private
   */
  private getSubscriptionPlanConfig(
    subscription: Subscription,
    setConfigOptions = true
  ): SubscriptionConfig {
    switch (subscription.planGroup) {
      case SubscriptionPlanGroupEnum.LegacyPlan:
      case SubscriptionPlanGroupEnum.NewPlan:
        this.groupedSubscriptionPlans.next([...this.legacySubscriptionPlans.value, ...this.newSubscriptionPlans.value]);
        break;
      case SubscriptionPlanGroupEnum.SupportOnlyPlan:
        this.groupedSubscriptionPlans.next(
          this.supportOnlySubscriptionPlans.value
        );
        break;
    }
    const canDowngrade =
      subscription?.term?.end === undefined ||
      subscription.status === StatusEnum.Draft;
    const edition = subscription.renewal.productTier;
    const successPlan = subscription.renewal.supportLevel;
    const billingFrequency = subscription.term.frequency;
    const duration = getDuration(subscription.term) as DurationEnum;
    const referenceSubscription = subscription;
    const config = {
      edition,
      successPlan,
      billingFrequency,
      duration,
      referenceSubscription,
    };
    if (setConfigOptions) {
      this.getEditions(canDowngrade ? undefined : edition);
      this.getSuccessPlans(edition, canDowngrade ? undefined : successPlan);
      this.getBillingFrequencies(config);
    }
    return config;
  }

  public getSubscriptionPlanFromSubscription(
    subscription: Subscription
  ): SubscriptionPlan {
    const config = this.getSubscriptionPlanConfig(subscription, false);
    return this.getSubscriptionPlanFromConfig(config);
  }

  /**
   * get subscription plans
   * of signed in account
   */
  public getSubscriptions(): Observable<Subscription[]> {
    return this.subscriptionService.getSubscriptions().pipe(
      map((subscriptions) => {
        const pendingActivationSubscriptions = subscriptions.filter(
          (subscription) => subscription.status === StatusEnum.PendingActivation
        );
        const draftSubscriptions = subscriptions.filter(
          (subscription) => subscription.status === StatusEnum.Draft
        );
        const activeSubscriptions = subscriptions.filter(
          (subscription) => subscription.status === StatusEnum.Active
        );
        const closedSubscriptions = subscriptions.filter(
          (subscription) => subscription.status === StatusEnum.Closed
        );
        const suspendedSubscriptions = subscriptions.filter(
          (subscription) => subscription.status === StatusEnum.Suspended
        );

        // link draft subscription to active subscription
        draftSubscriptions.forEach((sub) => {
          const matchingActiveSub = activeSubscriptions.find(
            (active) => `${active.id}` === `${sub?.parentSubscription}`
          );
          if (matchingActiveSub) {
            matchingActiveSub.draftRenewalSubscription = sub;
          }
        });
        // link pending activation subscription to active subscription
        pendingActivationSubscriptions.forEach((sub) => {
          const matchingActiveSub = activeSubscriptions.find(
            (active) => `${active.id}` === `${sub?.parentSubscription}`
          );
          if (matchingActiveSub) {
            matchingActiveSub.pendingActivationSubscription = sub;
            // detach draft subscription if pending activation exists
            matchingActiveSub.draftRenewalSubscription = null;
          }
        });

        return [
          ...activeSubscriptions,
          ...pendingActivationSubscriptions,
          ...suspendedSubscriptions,
          ...draftSubscriptions,
          ...closedSubscriptions,
        ];
      })
    );
  }

  /**
   * get subscription plans
   * of signed in account
   * filter out null plans
   * return empty list and do not call plans API if end user is not token customer
   */
  public getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.hasOneMatchingEndUserAndCustomer.value
      ? this.subscriptionService
          .getSubscriptionPlans()
          .pipe(
            map((plans) =>
              plans.filter((plan) => plan.productTier && plan.supportLevel)
            )
          )
      : of([]);
  }

  public getAllSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.subscriptionService.getSubscriptionPlans();
  }

  /** return ordered editions */
  public getEditions(filterEdition?: EditionEnum): EditionEnum[] {
    const editions = this.groupedSubscriptionPlans.value.map(
      (subscriptionPlan) => subscriptionPlan.productTier
    );
    const sortedEditions = SortUtility.sortEditions(editions).filter(
      (edition) => {
        return filterEdition
          ? SortUtility.getEditionOrder(edition) >=
              SortUtility.getEditionOrder(filterEdition)
          : true;
      }
    );

    this.editions.next(sortedEditions);
    return sortedEditions;
  }

  /** return ordered success plans */
  public getSuccessPlans(
    edition: EditionEnum,
    filterSuccessPlan?: SuccessPlanEnum
  ): SuccessPlanEnum[] {
    const successPlans = this.groupedSubscriptionPlans.value
      .filter((s) => s.productTier === edition)
      .map((subscriptionPlan) => subscriptionPlan.supportLevel);

    const sortedSuccessPlans = SortUtility.sortSuccessPlans(
      successPlans
    ).filter((successPlan) => {
      return filterSuccessPlan
        ? SortUtility.getSuccessPlanOrder(successPlan) >=
            SortUtility.getSuccessPlanOrder(filterSuccessPlan)
        : true;
    });

    this.successPlans.next(sortedSuccessPlans);
    return sortedSuccessPlans;
  }

  /** return ordered billing frequencies */
  public getBillingFrequencies(
    config: Partial<SubscriptionConfig>
  ): BillingFrequencyEnum[] {
    const items = this.getSubscriptionPlanFromConfig(config)?.items;
    const hasRequired = items.some((item) => item.required);
    const billingFrequencies = this.getSubscriptionPlanFromConfig(config)
      ?.items.filter((item) => item.required || !hasRequired)
      .reduce((frequency, item) => {
        item.prices.forEach((e) => {
          if (item.required || e.frequency !== BillingFrequencyEnum.OneTime) {
            frequency.push(e.frequency);
          }
        });
        return frequency;
      }, new Array<BillingFrequencyEnum>());
    const sortedBillingFrequencies =
      SortUtility.sortBillingFrequencies(billingFrequencies);
    this.billingFrequencies.next(sortedBillingFrequencies);
    return sortedBillingFrequencies;
  }

  /**
   * set radio buttons based on subscription
   * availability
   * @param subscription
   */
  public setPlanConfig(subscription?: Subscription): void {
    const config = subscription
      ? this.getSubscriptionPlanConfig(subscription)
      : this.getDefaultPlanConfig();
    this.selectedPlanConfig.next(config);
  }

  /** set selected billing frequency */
  public updatePlanConfig(
    config: SubscriptionConfig,
    filterSuccessPlan?: SuccessPlanEnum
  ): void {
    const successPlans = this.getSuccessPlans(
      config.edition,
      filterSuccessPlan
    );
    config.successPlan = successPlans.includes(config.successPlan)
      ? config.successPlan
      : successPlans[0];
    const billingFrequencies = this.getBillingFrequencies(config);
    config.billingFrequency = billingFrequencies.includes(
      config.billingFrequency
    )
      ? config.billingFrequency
      : billingFrequencies[0];
    this.selectedPlanConfig.next(config);
  }

  /** get subscription plan code */
  public getPlanCode(config: SubscriptionConfig): string {
    const plan = this.getSubscriptionPlanFromConfig(config);
    return plan.code;
  }

  /** get subscription plan code */
  public getPlanTitle(config: SubscriptionConfig): string {
    const plan = this.getSubscriptionPlanFromConfig(config);
    return plan?.title;
  }

  /** get subscription plan matching config */
  public getSubscriptionPlanFromConfig(
    config: Partial<SubscriptionConfig>
  ): SubscriptionPlan {
    const planFromSubscriptionPlanCode = this.groupedSubscriptionPlans?.value.find(
      (s) => s?.id === config.referenceSubscription?.plan?.id
      );

    const planFromSelectedConfig = this.groupedSubscriptionPlans?.value.find(
      (s) =>
        s?.productTier === config?.edition &&
        s?.supportLevel === config?.successPlan
    );

    const configMatched =
      planFromSubscriptionPlanCode?.productTier ===
        planFromSelectedConfig?.productTier &&
      planFromSubscriptionPlanCode?.supportLevel ===
        planFromSelectedConfig?.supportLevel;

    return configMatched
      ? planFromSubscriptionPlanCode
      : planFromSelectedConfig;
  }

  /** get alternative edition name */
  public getAlternativeEditionName(edition: EditionEnum): string {
    const alternateName = this.groupedSubscriptionPlans.value
      ?.filter((plan) => plan?.productTier === edition)
      .find((plan) => plan.productTierTitle?.length).productTierTitle;
    return alternateName ?? edition;
  }

  public activateSubscription(
    subscriptionId: number
  ): Observable<Subscription> {
    return this.subscriptionService.activateSubscription(subscriptionId);
  }

  /** customer is new if they do not have an active non-trial subscription */
  public isNewCustomer(): boolean {
    const subscriptions = this.subscriptions?.value ?? [];
    return !subscriptions.find(
      (subscription) =>
        !subscription.isTrial && subscription.status === StatusEnum.Active
    );
  }

  /**
   * Attempts to open the renewal details for a given subscription ID. If the details for the specified
   * subscription ID are not available, navigates to the home page.
   *
   * @param {string} subscriptionId - The ID of the subscription to find the renewal details for.
   * @returns {Observable<Subscription | void>} An observable that, when subscribed to, will either
   * open the renewal details modal for the subscription or navigate to the home page if the details cannot be opened.
   */
  openRenewalDetailsOrNavigateHome(
    subscriptionId: string
  ): Observable<Subscription> {
    const isUserAdmin              = HelperMethods.isUserAdmin(this.authFacade.getUserProperties());
    const isAdminRenewParamPresent = HelperMethods.checkAdminRenewParam();

    return this.subscriptions$.pipe(
      filter((subscriptions) => subscriptions.length > 0),
      map((subscriptions) =>
        subscriptions.find(
          (subscription) => subscription.id === parseInt(subscriptionId, 10) &&
            !!subscription.renewalOpportunity?.id &&
            !!subscription.draftRenewalSubscription &&
            (isUserAdmin && isAdminRenewParamPresent ? true : subscription.canRenew))
      ),
      take(1),
      tap((subscriptionDetail) => {
        if (subscriptionDetail) {
          console.log('Opening renewal details from subscription state');
          this.facade.openNewSubscriptionModal(
            SubscriptionActionEnum.Renew,
            subscriptionDetail
          );
        } else {
          this.facade.setStepIndex(TabIndices.HomePage);
        }
      })
    );
  }

  getSubscriptionById(subscriptionId: string): Observable<Subscription> {
    return this.subscriptionService.getSubscriptionById(subscriptionId);
  }
}
