import { Injectable } from '@angular/core';
import { AuthHttpClient } from 'app/core/http/services/auth.http.client';
import { FileListItems } from 'app/modules/widget/models/fileList.model';
import { QuoteRequest } from 'app/modules/widget/models/quote-request.model';
import { RenewalResponse } from 'app/modules/widget/models/renewal-response.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getCurrentLanguage } from '../../../translate';

@Injectable({ providedIn: 'root' })
export class RenewalFlowService {
  public kontentAiError$ = new BehaviorSubject<boolean>(false);
  private stopRequests$ = new Subject<void>();

  constructor(private readonly http: AuthHttpClient) {}

  public getAgreementStatus(opportunity: number): Observable<string> {
    return this.http.get(
      `${environment.apiUrl}ui/opportunity/${opportunity}/agreementStatus`,
      { responseType: 'text' }
    );
  }

  public uploadFile(opportunity: number, payload: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}opportunity/${opportunity}/document`, payload);
  }

  public getThePlanAndItems(
    subscriptionId: string,
    productTier: string,
    supportLevel: string
  ): Observable<RenewalResponse> {
    return this.http.get<RenewalResponse>(
      `${environment.apiUrl}subscription/${subscriptionId}/renewal`,
      {
        params: {
          productTier,
          supportLevel,
        },
      }
    );
  }

  createQuoteForSelfServe(
    requestBody: QuoteRequest
  ): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${environment.apiUrl}quote/createForSelfServe`,
      requestBody
    );
  }

  setCustomerInterests(subscriptionId: number, productCodes: string[]) {
    const body = {
      subscriptionId,
      productCodes,
    };

    return this.http.post(`${environment.apiUrl}ui/customer/interests/`, body);
  }

  renewalIntent(subscriptionId: string): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}subscription/${subscriptionId}/renewalIntent`,
      {}
    );
  }

  sendSupportMessage(
    subscriptionId: number,
    message: string,
    firstName: string,
    lastName: string,
    phone: string
  ) {
    const body = {
      subscriptionId,
      message,
      firstName,
      lastName,
      phone,
    };

    return this.http.post(`${environment.apiUrl}ui/customer/support`, body);
  }

  getContentFromKontentIo(pageId: string): Observable<any> {
    const language = getCurrentLanguage().code;
    const url = `${environment.kontentAPIUrl}items?system.type=${pageId}&depth=5&language=${language}`;
    return this.http.get(url).pipe(
      takeUntil(this.stopRequests$),
      catchError((error) => {
        this.kontentAiError$.next(true);
        this.stopRequests$.next();
        throw new Error(error.message);
      })
    );
  }

  cancelQuote(quoteId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}quote/${quoteId}/cancel`, {});
  }

  public getUploadedDocuments(opportunity: number): Observable<FileListItems[]> {
    return this.http.get<FileListItems[]>(`${environment.apiUrl}opportunity/${opportunity}/document`);
  }

  public closeOpportunity(opportunity: number): Observable<FileListItems[]> {
    return this.http.post<FileListItems[]>(`${environment.apiUrl}opportunity/${opportunity}/close`, {});
  }

  public deleteDocument(opportunity: number, documentId: number): Observable<FileListItems> {
    return this.http.delete<FileListItems>(`${environment.apiUrl}opportunity/${opportunity}/document`,
      {body: {documentId: documentId}});
  }
}
