// src/app/store/effects/renewal-contact.effects.ts
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RenewalContactService} from '../../services/renewal-contact.service';
import * as RenewalContactActions from './renewal-contact.actions';

@Injectable()
export class RenewalContactEffects {
  updateRenewalContact$ = createEffect(() => this.actions$.pipe(ofType(RenewalContactActions.updateRenewalContact),
    mergeMap(
      action => this.renewalContactService.updatePrimaryContact(action.subscriptionId, action.primaryContact).pipe(
        map(primaryContact => RenewalContactActions.updateRenewalContactSuccess({primaryContact})),
        catchError(error => of(RenewalContactActions.updateRenewalContactFailure({error})))))));

  loadRenewalContact$ = createEffect(() => this.actions$.pipe(ofType(RenewalContactActions.loadRenewalContact),
    mergeMap(action => this.renewalContactService.getPrimaryContact(action.subscriptionId).pipe(
      map(primaryContact => RenewalContactActions.loadRenewalContactSuccess({primaryContact})),
      catchError(error => of(RenewalContactActions.loadRenewalContactFailure({error})))))));

  constructor(private actions$: Actions, private renewalContactService: RenewalContactService) {}
}
