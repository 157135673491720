import { HttpBackend, HttpClient } from '@angular/common/http'
import { TranslateLoader } from '@ngx-translate/core'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { TranslationResource } from '../models'

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private handler: HttpBackend,
    private resourcesPrefix: string[] | TranslationResource[],
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const requests: Observable<Object | {}>[] = this.resourcesPrefix.map((resource) => {
      let path: string

      if (typeof resource === 'string') path = `${resource}${lang}.json`
      else path = `${resource.prefix}${lang}${resource.suffix || '.json'}`

      return new HttpClient(this.handler).get(path).pipe(
        catchError((res) => {
          if (typeof resource !== 'string' && !resource.optional) {
            console.group()
            console.error('Something went wrong for the following translation file:', path)
            console.error(res)
            console.groupEnd()
            throw res;
          }
          return of({})
        }),
      )
    })

    return forkJoin(requests).pipe(
      map((response) => response.reduce((acc, curr) => this.mergeDeep(acc, curr), {})),
    )
  }

  isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item)
  }

  mergeDeep(target: any, source: any): any {
    const output = Object.assign({}, target)

    if (!this.isObject(target)) {
      return this.mergeDeep({}, source)
    }

    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach((key: any) => {
        if (this.isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] })
          } else {
            output[key] = this.mergeDeep(target[key], source[key])
          }
        } else {
          Object.assign(output, { [key]: source[key] })
        }
      })
    }
    return output
  }
}
