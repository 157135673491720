import { Position } from '@progress/kendo-angular-notification';
import { NotificationSettings } from '@progress/kendo-angular-notification';

export const NOTIFICATION_DURATION = 6000;

export const NOTIFICATION_POSITION: Position = {
  horizontal: 'center',
  vertical: 'bottom',
};
export const NOTIFICATION_POSITION_SUCCESS: Position = {
  horizontal: 'center',
  vertical: 'top',
};

export const DEFAULT_NOTIFICATION_SETTINGS: Partial<NotificationSettings> = {
  hideAfter: NOTIFICATION_DURATION,
  type: { style: 'info' },
  position: NOTIFICATION_POSITION,
};

export const NOTIFICATION_SETTINGS_SUCCESS_FOR_MODAL: Partial<NotificationSettings> = {
  hideAfter: NOTIFICATION_DURATION, type: {style: 'success'}, position: NOTIFICATION_POSITION
};

export const NOTIFICATION_SETTINGS_ERROR_FOR_MODAL: Partial<NotificationSettings> = {
  hideAfter: NOTIFICATION_DURATION, type: {style: 'error'}, position: NOTIFICATION_POSITION
};

export const NOTIFICATION_SETTINGS_SUCCESS: Partial<NotificationSettings> = {
  hideAfter: NOTIFICATION_DURATION,
  type: { style: 'success' },
  position: NOTIFICATION_POSITION_SUCCESS,
};

export const NOTIFICATION_SETTINGS_ERROR: Partial<NotificationSettings> = {
  hideAfter: NOTIFICATION_DURATION,
  type: { style: 'error' },
  position: NOTIFICATION_POSITION_SUCCESS,
};
