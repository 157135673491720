import { HttpBackend } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { UiStorageKey } from './core/enums/ui-storage-key.enum';
import { UserPreferences } from './core/models/user-preferences.model';
import { BUService } from './core/services/bu-service';
import { UiStorageService } from './core/services/ui-storage.service';
import { initializeTranslations } from './translate';
import { LOCAL_TRANSLATIONS_PATH, TRANSLATIONS, TRANSLATIONS_VERSION } from './translate/constants';

export function appInitializeFactory(
  buService: BUService,
  httpBackend: HttpBackend,
  translateService: TranslateService,
  uiStorageService: UiStorageService
): () => Promise<void> {
  const translationsPath = location.hostname === 'localhost' 
    ? LOCAL_TRANSLATIONS_PATH
    : environment.translationsPath;

  return async () => {
    let userPreferences: UserPreferences | null = null;

    try {
      userPreferences = await uiStorageService
        .get<UserPreferences>(UiStorageKey.USER_PREFERENCES)
        .toPromise();
    } catch (error) {
      // It is not an issue if there is no user preferences yet
      console.warn('Cannot get user preferences', error);
    }

    const language = await initializeTranslations(
      translateService,
      httpBackend,
      translationsPath,
      TRANSLATIONS_VERSION,
      TRANSLATIONS,
      userPreferences?.language
    );

    buService.loadBUUnits();

    if (language && (!userPreferences || userPreferences.language !== language)) {
      userPreferences = {
        ...(userPreferences || {}),
        language,
      };
      uiStorageService.put(UiStorageKey.USER_PREFERENCES, userPreferences).subscribe();
    }
  };
}
