import { Address } from '@tsi/tsi/customer';
import { HelperMethods } from 'app/modules/widget/utils/helper-methods';
import { JsonProperty } from 'json-object-mapper';

export interface PrimaryContact {
  address?: Address;
  name: CustomerName;
  email: string;
  phone: string;
}

export interface CustomerName {
  firstName: string;
  lastName: string;
  title?: string;
  middleName?: string;
}
export class Customer {
  /** customer id   */
  @JsonProperty() public id: string;
  /** customer email */
  @JsonProperty() public email: string;
  /** customer phone number */
  @JsonProperty() public phone: string;
  /** company name */
  @JsonProperty() public companyName: string;
  /** full name */
  @JsonProperty() public fullName?: string;
  /** job title */
  @JsonProperty() public jobTitle?: string;

  @JsonProperty() public address?: Address;

  @JsonProperty() public shippingAddress?: Address;

  /** Primary Contact */
  @JsonProperty() public primaryContact?: PrimaryContact;
  @JsonProperty() public taxIdentificationNumber?: string;

  constructor() {
    this.id = null;
    this.email = null;
    this.companyName = null;
    this.phone = null;
    this.fullName = null;
    this.jobTitle = null;
    this.primaryContact = null;
    this.address = null;
    this.shippingAddress = null;
    this.taxIdentificationNumber = null;
  }

  get primaryContactName() {
    const firstName = this.primaryContact?.name?.firstName;
    const lastName  = this.primaryContact?.name?.lastName;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return null;
    }
  }

  get addressForUiPresentation() {
    // Create an array with all parts of the address
    const addressParts = [this.companyName, this.address.line1, this.address.line2, this.address.city,
      this.address.state, this.address.zip, HelperMethods.getCountryNameByCode(this.address.country)].filter(Boolean);

    // Join the parts with a comma and a space, only between non-empty fields
    return addressParts.join(', ');
  }

  get shippingAddressForUiPresentation() {
    // Check if there is a shipping address first
    if (!this.shippingAddress) return null;

    // Create an array with all parts of the shipping address
    const shippingAddressParts = [this.companyName, this.shippingAddress.line1, this.shippingAddress.line2,
      this.shippingAddress.city, this.shippingAddress.state, this.shippingAddress.zip,
      HelperMethods.getCountryNameByCode(this.shippingAddress.country)].filter(Boolean);

    // Join the parts with a comma and a space, only between non-empty fields
    return shippingAddressParts.join(', ');
  }

  get endUserShippingAddress() {
    return this.shippingAddress ? this.shippingAddress : this.address;
  }
}
