import { JsonProperty } from 'json-object-mapper';
import { PreviewPricingItem } from './preview-pricing-item.model';

export class PreviewForRenewalResponse {
  @JsonProperty() public floorPriceApplied?: boolean;
  @JsonProperty() public frequency?: string;
  @JsonProperty() public items: PreviewPricingItem[];
  @JsonProperty() public onlyPlatinum: boolean;
  @JsonProperty() public planCode: string;
  @JsonProperty() public successPlanPremium: number;
  @JsonProperty() public totalAmount: number;
  @JsonProperty() public totalListPrice: number;

  constructor() {
    this.floorPriceApplied = false;
    this.frequency = null;
    this.items = [];
    this.onlyPlatinum = null;
    this.planCode = null;
    this.successPlanPremium = null;
    this.totalAmount = 0;
    this.totalListPrice = 0;
  }

  get annualRenewalPrice(): number {
    return this.totalAmount;
  }

  get discountAmount(): number {
    return this.totalListPrice > this.totalAmount
      ? this.totalListPrice - this.totalAmount
      : 0;
  }

  get getDiscountPercentage(): number {
    if (this.totalListPrice <= 0 && this.totalListPrice < this.totalAmount) {
      return 0;
    }

    const discount = (this.discountAmount / this.totalListPrice) * 100;
    return Math.round(discount);
  }

  get isDiscountPresent(): boolean {
    return this.totalListPrice >= 0 && this.totalAmount < this.totalListPrice;
  }
}
