import {createAction, props} from '@ngrx/store';
import {PrimaryContact} from 'app/modules/widget/models/customer.model';

export const loadRenewalContact = createAction('[Renewal Contact] Load Renewal Contact',
  props<{ subscriptionId: number }>());

export const loadRenewalContactSuccess = createAction('[Renewal Contact] Load Renewal Contact Success',
  props<{ primaryContact: Partial<PrimaryContact> }>());

export const loadRenewalContactFailure = createAction('[Renewal Contact] Load Renewal Contact Failure',
  props<{ error: any }>());
export const updateRenewalContact      = createAction('[Renewal Contact] Update Renewal Contact',
  props<{ subscriptionId: number, primaryContact: Partial<PrimaryContact> }>());

export const updateRenewalContactSuccess = createAction('[Renewal Contact] Update Renewal Contact Success',
  props<{ primaryContact: Partial<PrimaryContact> }>());

export const updateRenewalContactFailure = createAction('[Renewal Contact] Update Renewal Contact Failure',
  props<{ error: any }>());
