import { HttpBackend } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CURRENT_LANGUAGE_STORAGE_KEY, LANGUAGE_CHANGED_EVENT_TYPE, LANGUAGES, LOCALES_MAP } from '../constants';
import { Language } from '../enums';
import { LanguageData, TranslationResource } from '../models';
import { loadAndApplyTranslations } from './translations-loading.utils';

export function getLanguageData(language: string): LanguageData | undefined {
  return LANGUAGES.find((item: LanguageData) => item.code === language || item.locale === language);
}

export function getCurrentLanguage(): LanguageData {
  let lang = getLanguageFromLocalStorage() || getBrowserLanguage() || Language.English;

  if (!isValidLanguage(lang)) {
    lang = Language.English;
  }

  return getLanguageData(lang) as LanguageData;
}

function getLanguageFromLocalStorage(): string | null {
  const lang = localStorage.getItem(CURRENT_LANGUAGE_STORAGE_KEY);
  return lang && lang !== '""' ? lang.replace(/"/g, '') : null;
}

function getBrowserLanguage(): string | null {
  if (typeof window !== 'undefined' && navigator) {
    const browserLang = navigator.languages?.[0] || navigator.language;
    return browserLang ? parseLanguageCode(browserLang) : null;
  }
  return null;
}

function parseLanguageCode(langCode: string): string {
  return langCode.split(/[-_]/)[0];
}

function isValidLanguage(lang: string): boolean {
  return Object.values(Language).includes(lang as Language);
}

export function setCurrentLanguage(language: string, translateService: TranslateService): void {
  localStorage.setItem(CURRENT_LANGUAGE_STORAGE_KEY, language);
  if (translateService.currentLang !== language) {
    translateService.use(language);
  }
}

export async function initializeTranslations(
  translateService: TranslateService,
  httpBackend: HttpBackend,
  translationsPath: string,
  version: string,
  translations: TranslationResource[],
  preferredLanguage?: string
): Promise<Language> {
  const language: Language = initializeLanguage(translateService, preferredLanguage);
  await loadAndApplyTranslations(translateService, httpBackend, translationsPath, version, translations);
  translateService.use(language);
  translateService.onLangChange.subscribe((event: LangChangeEvent) => {
    const iframe: HTMLIFrameElement | null = document.getElementById('tsi-portal-widget') as HTMLIFrameElement;
    if (!iframe) {
      return;
    }
    iframe.contentWindow.postMessage({
      type: LANGUAGE_CHANGED_EVENT_TYPE,
      payload: {
        lang: event.lang
      }
    }, '*');
  });
  window.addEventListener('message', (event: MessageEvent) => {
    if (event.data.type === LANGUAGE_CHANGED_EVENT_TYPE) {
      setCurrentLanguage(event.data.payload.lang, translateService);
    }
  });
  return language;
}

function initializeLanguage(translateService: TranslateService, preferredLanguage?: string): Language {
  let languageData: LanguageData;
  let language: Language;

  if (preferredLanguage && isValidLanguage(preferredLanguage)) {
    languageData = getLanguageData(preferredLanguage) as LanguageData;
    language = languageData.code as Language;
    localStorage.setItem(CURRENT_LANGUAGE_STORAGE_KEY, language);
  } else {
    languageData = getCurrentLanguage();
    language = languageData.code as Language;
  }

  if (!LOCALES_MAP.has(language)) {
    languageData = getLanguageData(Language.English) as LanguageData;
    language = languageData.code as Language;
  }
  translateService.addLangs(LANGUAGES.map((item: LanguageData) => item.code));
  return language;
}
