import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { MixpanelService } from '../../modules/widget/services/mixpanel.service';
import { Language } from '../enums';

@Injectable({ providedIn: 'root' })
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  private processing: boolean = false;
  private reportedKeys = new Set<string>();

  constructor(private mixpanelService: MixpanelService) {}

  handle(params: MissingTranslationHandlerParams): string {
    if (this.processing || !this.isTranslationKey(params.key)) {
      return params.key;
    }
    this.processing = true;
    const key = params.key;
    try {
      const lang = params.translateService.currentLang;

      // Only report each missing key once per session
      if (!!lang && !this.reportedKeys.has(key)) {
        this.reportedKeys.add(key);
        this.mixpanelService.trackMissingTranslation(key, lang);
        console.warn(`Missing translation for key "${key}" in language "${lang}"`);
      }
      if (!lang || lang === Language.English) {
        return key;
      }
      const enTranslations = params.translateService.translations[Language.English] || {};
      const translation = params.translateService.getParsedResult(enTranslations, key, params.interpolateParams) || key;
      return translation;
    } catch (error) {
      console.error(`Error handling missing translation for key "${key}":`, error);
      return key;
    } finally {
      this.processing = false;
    }
  }

  private isTranslationKey(key: string): boolean {
    return (
      !!key &&
      key.includes('.') &&
      !key.startsWith('.') &&
      !key.endsWith('.') &&
      !key.includes(' ') &&
      key.toUpperCase() === key
    );
  }
}
