import { createAction, props } from '@ngrx/store';
import { CustomerInformation } from 'libs/tsi/customer/src/lib/models';

export const init = createAction('[Customer Page] Init');

export const reset = createAction('[Customer Page] Reset');

export const save = createAction(
  '[Customer Page] Update Customer Information',
  props<{
    customer: Partial<CustomerInformation>;
    showNotification?: boolean;
  }>()
);

export const loadCustomerSuccess = createAction(
  '[Customer/API] Load Customer Success',
  props<{ customer: Partial<CustomerInformation[]> }>()
);

export const updateCustomerSuccess = createAction(
  '[Customer/API] Update Customer Success',
  props<{ customer: Partial<CustomerInformation[]> }>()
);

export const saveShippingAddress = createAction(
  '[Customer] Save Shipping Address',
  props<{
    endUserId: string;
    shippingAddress: any;
    showNotification?: boolean;
  }>()
);

export const saveShippingAddressSuccess = createAction(
  '[Customer API] Save Shipping Address Success'
);

export const saveShippingAddressFailure = createAction(
  '[Customer API] Save Shipping Address Failure',
  props<{ error: string }>()
);

export const saveCustomerData = createAction(
  '[Customer] Save Customer Data',
  props<{ customerId: string; customerData: any; showNotification?: boolean }>()
);

export const saveCustomerDataSuccess = createAction(
  '[Customer API] Save Customer Data Success'
);

export const resetShippingAddressSaveStatus = createAction(
  '[Customer] Reset Shipping Address Save Status'
);
export const resetCustomerDataSaveStatus = createAction(
  '[Customer] Reset Customer Data Save Status'
);

export const saveCustomerDataFailure = createAction(
  '[Customer API] Save Customer Data Failure',
  props<{ error: string }>()
);

export const resetSaveStatus = createAction('[Customer] Reset Save Status');

export const resetError = createAction('[Customer] Reset Error');

export const loadCustomerFailure = createAction(
  '[Customer/API] Load Customer Failure',
  props<{ error: string }>()
);

export const updateCustomerFailure = createAction(
  '[Customer/API] Update Customer Failure',
  props<{ error: string }>()
);
