import {
  CompositeFilterDescriptor, FilterDescriptor
} from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';

export class KendoGridUtility {
  public static flatten(filter: CompositeFilterDescriptor): (CompositeFilterDescriptor | FilterDescriptor)[] {
    const filters = filter.filters;
    if (filters) {
      return filters.reduce(
        (
          acc: (CompositeFilterDescriptor | FilterDescriptor)[],
          curr
        ) => acc.concat((curr as CompositeFilterDescriptor).filters
          ? this.flatten(curr as CompositeFilterDescriptor)
          : [curr]),
        [] as CompositeFilterDescriptor[]
      );
    }
    return [] as CompositeFilterDescriptor[];
  }
}
