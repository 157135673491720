import {Injectable} from '@angular/core';
import {AuthHttpClient} from 'app/core/http/services/auth.http.client';
import {PrimaryContact} from 'app/modules/widget/models/customer.model';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RenewalContactService {
  private apiUrl = `${environment.apiUrl}subscription`;

  constructor(private readonly httpClient: AuthHttpClient) {}

  getPrimaryContact(subscriptionId: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/${subscriptionId}/getRenewalPrimaryContact`);
  }

  updatePrimaryContact(subscriptionId: number, primaryContact: Partial<PrimaryContact>): Observable<any> {
    return this.httpClient.patch(`${this.apiUrl}/${subscriptionId}/updateRenewalPrimaryContact`, {primaryContact});
  }
}
