import {JsonProperty} from 'json-object-mapper';
import {InitialTermTypeEnum} from 'src/app/modules/widget/enums/initial-term-type.enum';

export class Initialterm {
  /** term name */
  @JsonProperty() public name: string;
  /** term unit */
  @JsonProperty() public unit: string;
  /** term duration */
  @JsonProperty() public duration: number;
  /** term type */
  @JsonProperty() public type: InitialTermTypeEnum;

  constructor() {
    this.name     = null;
    this.unit     = null;
    this.duration = null;
    this.type     = null;
  }
}
