export enum DenialReason {
  SubscriptionEndDateNotCloseEnough = 'Subscription end date is not close enough',
  SubscriptionExcludedFromSelfServe = 'Subscription is excluded from self serve',
  SubscriptionIsPrime = 'Subscription is prime',
  SubscriptionSoldThroughReseller = 'Customer is not the subscription customer',
  SubscriptionHasPendingActivation = 'Subscription has a pending activation',
  SubscriptionHasTerminated = 'The related draft subscription was terminated',
  SubscriptionIsOfZero = 'Subscription is of $0',
  ProductNotEnabledForSelfServe = 'Product is not enabled for Self-Serve',
  SubscriptionHasNoDraftRenewal = 'Subscription has no draft renewal',
  SubscriptionIsNotActive = 'Subscription is not active',
  RenewalOpportunityIsAlreadyClosed = 'Renewal opportunity is already closed',
  UpsellQuoteIsAlreadySigned = 'Upsell quote is already signed',
}
