export enum OpportunityStatus {
  INACTIVE = 'Inactive',
  NON_RENEWING = 'Non Renewing',
  SUSPENDED = 'Suspended',
  PROSPECT = 'Prospect',
  IN_DISCUSSION = 'In Discussion',
  PROPOSAL = 'Proposal',
  ACTIVE = 'Active',
  QUOTE_REQUESTED = 'Quote Requested',
  NEGOTIATION = 'Negotiation',
  SIGNING = 'Signing',
  AUTORENEWAL = 'Autorenewal',
  PENDING = 'Pending',
  CLOSED_PENDING = 'Closed - Pending',
  CLOSED_WON = 'Closed-Won'
}
