import { JsonProperty } from 'json-object-mapper';
import {
  EditionEnum,
  EditionTitleEnum,
} from 'src/app/modules/widget/enums/edition.enum';
import { SuccessPlanEnum } from 'src/app/modules/widget/enums/success-plan.enum';

export class Plan {
  /** plan id */
  @JsonProperty() public id: number;
  /** plan code */
  @JsonProperty() public code: string;
  /** is plan trial */
  @JsonProperty() public isTrial: boolean;
  /** product tier */
  @JsonProperty() public productTier: EditionEnum;
  /** product tier title */
  @JsonProperty() public productTierTitle: EditionTitleEnum;
  /** support level */
  @JsonProperty() public supportLevel: SuccessPlanEnum;
  /** title */
  @JsonProperty() public title: string;
  /** description */
  @JsonProperty() public description?: string;
  /** revenue type */
  @JsonProperty() public revenueType?: string;
  /** enable subscription items quantity edit */
  @JsonProperty() public enableItemsQuantityEdit?: boolean;

  constructor() {
    this.id = null;
    this.code = null;
    this.isTrial = null;
    this.productTier = null;
    this.supportLevel = null;
    this.title = null;
    this.description = null;
    this.revenueType = null;
    this.enableItemsQuantityEdit = null;
  }
}
