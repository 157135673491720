import { Injectable } from '@angular/core';
import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthHttpClient } from 'src/app/core/http/services/auth.http.client';
import { StatusEnum } from 'src/app/modules/widget/enums/status.enum';
import { PreviewForModifyRequest } from 'src/app/modules/widget/models/preview-for-modify-request.model';
import { PreviewForModifyResponse } from 'src/app/modules/widget/models/preview-for-modify-response.model';
import { PreviewForRenewalRequest } from 'src/app/modules/widget/models/preview-for-renewal-request.model';
import { PreviewForRenewalResponse } from 'src/app/modules/widget/models/preview-for-renewal-response.model';
import { SubscriptionPlan } from 'src/app/modules/widget/models/subscription-plan.model';
import { Subscription } from 'src/app/modules/widget/models/subscription.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private readonly http: AuthHttpClient) {}

  getSubscriptions(): Observable<Subscription[]> {
    const statusList = [
      StatusEnum.Active,
      StatusEnum.PendingActivation,
      StatusEnum.Draft,
    ];
    let queryParams = '';
    for (const [i, status] of statusList.entries()) {
      queryParams = queryParams + (i ? '&' : '') + `statuses[${i}]=${status}`;
    }
    return this.http
      .get<Subscription[]>(`${environment.apiUrl}subscriptions?${queryParams}`)
      .pipe(
        map((payload) => {
          return ObjectMapper.deserializeArray(Subscription, payload);
        })
      );
  }

  getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.http
      .get<SubscriptionPlan[]>(`${environment.apiUrl}subscriptionPlans`)
      .pipe(
        map((payload) => {
          return ObjectMapper.deserializeArray(SubscriptionPlan, payload);
        })
      );
  }

  getPreviewForRenewal(
    requestBody: PreviewForRenewalRequest,
    subscriptionId: string
  ): Observable<PreviewForRenewalResponse> {
    return this.http
      .post<PreviewForRenewalResponse>(
        `${environment.apiUrl}subscription/preview/${subscriptionId}`,
        requestBody
      )
      .pipe(
        map((response) => ObjectMapper.deserialize(PreviewForRenewalResponse, response))
      );
  }

  getPreviewForModify(
    requestBody: PreviewForModifyRequest,
    subscriptionId: string
  ): Observable<PreviewForModifyResponse> {
    return this.http
      .post<PreviewForModifyResponse>(`${environment.apiUrl}subscription/${subscriptionId}/previewForUpsell`, requestBody)
      .pipe(map((response) => ObjectMapper.deserialize(PreviewForModifyResponse, response)));
  }

  getSubscriptionById(subscriptionId: string) {
    return this.http
      .get<Subscription>(`${environment.apiUrl}subscription/${subscriptionId}`)
      .pipe(map((response) => {
          return ObjectMapper.deserialize(Subscription, response);
        })
      );
  }

  /** activate subscription */
  activateSubscription(
    subscriptionId: number
  ): Observable<Subscription> {
    return this.http
      .patch<Subscription>(
        `${environment.apiUrl}subscription/${subscriptionId}`,
        { status: StatusEnum.Active }
      )
      .pipe(
        map((response) => {
          return ObjectMapper.deserialize(Subscription, response);
        })
      );
  }
}
