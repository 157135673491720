import {ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

const ERROR_MESSAGES: {[index: string]: string} = {
  required: 'SHARED.FORM.ERRORS.REQUIRED',
  capitalLetter: 'SHARED.FORM.ERRORS.CAPITAL_LETTER',
  number: 'SHARED.FORM.ERRORS.NUMBER',
  specialChar: 'SHARED.FORM.ERRORS.SPECIAL_CHAR',
  minlength: 'SHARED.FORM.ERRORS.MIN_LENGTH',
  matchPassword: 'SHARED.FORM.ERRORS.MATCH_PASSWORD'
};

export const getErrorMessage$ = (errors: ValidationErrors, translate: TranslateService) => {
  let errorMessage = ERROR_MESSAGES[Object.keys(errors)[0]];
  return translate.stream(
    errorMessage,
    errorMessage === ERROR_MESSAGES.minlength ?
      { minLength: errors.minlength.requiredLength } :
      {}
  );
};
