import { JsonProperty } from 'json-object-mapper';
import { EditionTitleEnum } from '../enums/edition.enum';
import { EditionEnum } from '../enums/edition.enum';
import { SuccessPlanEnum } from '../enums/success-plan.enum';

export class Renewal {
  @JsonProperty() public productTier: EditionEnum;
  @JsonProperty() public productTierTitle: EditionTitleEnum;
  @JsonProperty() public supportLevel: SuccessPlanEnum;
  @JsonProperty() public enableItemsQuantityEdit: boolean;

  constructor() {
    this.productTier = null;
    this.productTierTitle = null; 
    this.supportLevel = null;
    this.enableItemsQuantityEdit = false;
  }
}
