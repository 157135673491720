export enum EditionEnum {
  Standard = 'Standard',
  Professional = 'Professional',
  Enterprise = 'Enterprise',
}

export enum EditionTitleEnum {
  Basic = 'Basic',
  Content = 'Content',
  Engage = 'Engage',
}

export enum EditionTitleEnumUI {
  Standard = 'Basic',
  Professional = 'Content',
  Enterprise = 'Engage',
}
