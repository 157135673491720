import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import {
  NOTIFICATION_SETTINGS_ERROR, NOTIFICATION_SETTINGS_ERROR_FOR_MODAL, NOTIFICATION_SETTINGS_SUCCESS,
  NOTIFICATION_SETTINGS_SUCCESS_FOR_MODAL
} from '@tsi/shared/util';

@Injectable({ providedIn: 'root' })
export class NotificationState {
  constructor(private notificationService: NotificationService) {}

  public showNotification(message: string, container?: ViewContainerRef): void {
    this.notificationService.show({
      content: message, appendTo: container,
      ...NOTIFICATION_SETTINGS_SUCCESS,
    });
  }

  public showSuccessNotificationForModal(message: string, container?: ViewContainerRef): void {
    this.notificationService.show({
      content: message, appendTo: container, ...NOTIFICATION_SETTINGS_SUCCESS_FOR_MODAL
    });
  }

  public showErrorNotificationForModal(message: string, container?: ViewContainerRef): void {
    this.notificationService.show({
      content: message, appendTo: container, ...NOTIFICATION_SETTINGS_ERROR_FOR_MODAL
    });
  }

  public showErrorNotification(message: string, container?: ViewContainerRef) {
    this.notificationService.show({
      content: message, appendTo: container,
      ...NOTIFICATION_SETTINGS_ERROR,
    });
  }

  public showErrorTemplateNotification(template: TemplateRef<undefined>) {
    this.notificationService.show({
      content: template,
      ...NOTIFICATION_SETTINGS_ERROR,
    });
  }
}
