import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  LANGUAGES,
  getCurrentLanguage,
  setCurrentLanguage,
} from 'app/translate';
import { UiStorageService } from 'app/core/services/ui-storage.service';
import { UiStorageKey } from 'app/core/enums/ui-storage-key.enum';
import { UserPreferences } from 'app/core/models/user-preferences.model';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent {
  languages = LANGUAGES.map((data) => ({
    text: data.nativeShortName.toUpperCase(),
    value: data.code,
  }));
  selectedLanguage = getCurrentLanguage().code;

  constructor(
    private translate: TranslateService,
    private uiStorageService: UiStorageService
  ) {}

  switchLanguage(language: string) {
    setCurrentLanguage(language, this.translate);
    const preferences: UserPreferences = { language };
    this.uiStorageService
      .put(UiStorageKey.USER_PREFERENCES, preferences)
      .subscribe();
  }

  getLanguageFlagClass(lang: string): string {
    const countryCode = LANGUAGES.find(
      (data) => data.code === lang
    )!.countryCode;
    return `fi fi-${countryCode.toLowerCase()}`;
  }
}
