import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function emailAndCcValidator(
  formGroup: UntypedFormGroup
): null | { mismatch?: boolean; invalidEmails?: boolean } {
  let errors: { mismatch?: boolean; invalidEmails?: boolean } = {};

  // Email Match Validation
  const email = formGroup.get('email')?.value;
  const confirmEmail = formGroup.get('confirmEmail')?.value;
  if (email !== confirmEmail) {
    errors.mismatch = true;
    formGroup.get('confirmEmail')?.setErrors({ mismatch: true });
  } else {
    formGroup.get('confirmEmail')?.setErrors(null);
  }

  // CC Emails Validation
  const ccEmails = formGroup.get('ccEmails')?.value;
  if (ccEmails) {
    const trimmedCcEmails = ccEmails.trim();

    // Check for consecutive separators
    if (/[,;]{2,}/.test(trimmedCcEmails)) {
      errors.invalidEmails = true;
      formGroup.get('ccEmails')?.setErrors({invalidEmails: true});
    } else {
      // Allow trailing ";" or ","
      const adjustedCcEmails = trimmedCcEmails.replace(/[,;]$/, '');

      const emails        = adjustedCcEmails.split(/[;,]/).map((email: string) => email.trim()).filter(
        (email: string) => email !== '');
      const invalidEmails = emails.some((email: string) => !isValidEmail(email));
      if (invalidEmails) {
        errors.invalidEmails = true;
        formGroup.get('ccEmails')?.setErrors({invalidEmails: true});
      } else {
        formGroup.get('ccEmails')?.setErrors(null);
      }
    }
  } else {
    formGroup.get('ccEmails')?.setErrors(null);
  }

  return Object.keys(errors).length ? errors : null;
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const email = control.value;
  if (email) {
    if (!isValidEmail(email)) {
      return { invalidEmail: true };
    }
  }
  return null;
}

function isValidEmail(email: string): boolean {
  // Regex pattern that ensures:
  // 1. No dots immediately before @ symbol
  // 2. Valid local part (before @) with allowed characters
  // 3. Valid domain part (after @) with allowed characters and at least one dot
  const emailRegex = /^(?!.*\.\.)(?!\.)([A-Za-z0-9._%+\-]+)(?<!\.)@([A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,})$/;
  return emailRegex.test(email);
}
