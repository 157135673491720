import { DenialReason } from 'app/modules/widget/enums/denial-reason.enum';

export const DENIAL_MESSAGES: { [key in DenialReason]?: string } = {
  [DenialReason.SubscriptionEndDateNotCloseEnough]: 'SHARED.DENIAL_MESSAGES.SUBSCRIPTION_END_DATE_NOT_CLOSE_ENOUGH',
  [DenialReason.SubscriptionIsPrime]: 'SHARED.DENIAL_MESSAGES.SUBSCRIPTION_IS_PRIME',
  [DenialReason.SubscriptionSoldThroughReseller]: 'SHARED.DENIAL_MESSAGES.SUBSCRIPTION_SOLD_THROUGH_RESELLER',
  [DenialReason.SubscriptionHasPendingActivation]: 'SHARED.DENIAL_MESSAGES.SUBSCRIPTION_HAS_PENDING_ACTIVATION',
  [DenialReason.SubscriptionHasTerminated]: 'SHARED.DENIAL_MESSAGES.SUBSCRIPTION_HAS_TERMINATED',
  [DenialReason.RenewalOpportunityIsAlreadyClosed]: 'SHARED.DENIAL_MESSAGES.SUBSCRIPTION_HAS_PENDING_ACTIVATION',
  [DenialReason.UpsellQuoteIsAlreadySigned]: 'SHARED.DENIAL_MESSAGES.UPSELL_QUOTE_IS_ALREADY_SIGNED',
};
