import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './renewal-contact.reducer';

export const selectRenewalContactState = createFeatureSelector<State>('renewalContact');

export const selectPrimaryContact = createSelector(selectRenewalContactState, (state: State) => state.primaryContact);

export const selectRenewalContactError = createSelector(selectRenewalContactState, (state: State) => state.error);

export const selectRenewalContactLoading = createSelector(selectRenewalContactState, (state: State) => state.loading);

export const selectUpdateSuccess = createSelector(selectRenewalContactState, (state: State) => state.updateSuccess);

export const selectLoadSuccess = createSelector(selectRenewalContactState, (state: State) => state.loadSuccess);
