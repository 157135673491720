import {Term} from 'src/app/modules/widget/models/term.model';

export const getDuration = (term: Term): number => {
  if (term?.end) {
    const startDate        = new Date(term.start);
    const endDate          = new Date(term.end);
    const yearsDifference  = endDate.getFullYear() - startDate.getFullYear();
    const monthsDifference = endDate.getMonth() - startDate.getMonth();
    const daysDifference   = endDate.getDate() - startDate.getDate();
    let monthCorrection    = 0;
    if (daysDifference > 0) {
      monthCorrection = 1;
    }
    return (yearsDifference * 12 + monthsDifference + monthCorrection);
  }
  return 0;
};
