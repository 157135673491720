import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProgressStateService} from 'app/core/services/progress-loader.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-progress-loader', templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'], changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressLoaderComponent implements OnInit, OnDestroy {
  @Input() sentences: string[];
  // Time in milliseconds
  @Input() intervalTime           = 5000;
  @Input() progressUpdateInterval = 300;

  progressValue: number;
  currentSentence: string;
  private subscriptions: Subscription = new Subscription();

  constructor(private _cdr: ChangeDetectorRef, private progressStateService: ProgressStateService) {}

  ngOnInit(): void {
    this.sentences = this.sentences || [];
    // Start progress but do not reset it if already started
    this.progressStateService.startProgress(this.progressUpdateInterval, this.intervalTime, this.sentences.length);

    this.subscriptions.add(this.progressStateService.progressValue$.subscribe(value => {
      this.progressValue = value;
      this._cdr.markForCheck();
    }));

    this.subscriptions.add(this.progressStateService.currentSentenceIndex$.subscribe(index => {
      this.currentSentence = this.sentences[index] || '';
      this._cdr.markForCheck();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
