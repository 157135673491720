import {JsonProperty} from 'json-object-mapper';

export class Address {
  /** primary address line */
  @JsonProperty() public line1?: string;
  /** secondary address line */
  @JsonProperty() public line2?: string;
  /** holds city name */
  @JsonProperty() public city?: string;
  /** holds state name */
  @JsonProperty() public state?: string;
  /** holds country name */
  @JsonProperty() public country?: string;
  /** holds zip code */
  @JsonProperty() public zip?: string;
  /** holds address phone */
  @JsonProperty() public phone?: string;

  constructor() {
    this.line1   = null;
    this.line2   = null;
    this.city    = null;
    this.state   = null;
    this.country = null;
    this.zip     = null;
    this.phone   = null;
  }
}
