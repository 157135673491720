import { ChipThemeColor } from '@progress/kendo-angular-buttons';
import {
  NON_LEGACY_SUBSCRIPTION_PLAN_REGEX,
  SUPPORT_ONLY_SUBSCRIPTION_PLAN_REGEX,
} from '@tsi/shared/util';
import { CustomerPOStatus } from 'app/modules/widget/enums/customer-po-status.enum';
import { DenialReason } from 'app/modules/widget/enums/denial-reason.enum';
import { QuoteStatus } from 'app/modules/widget/enums/quote-status.enum';
import { SubscriptionPlanGroupEnum } from 'app/modules/widget/enums/subscription-plan-group.enum';
import { OptionDenialReason } from 'app/modules/widget/models/option-denial-reason.model';
import { DENIAL_MESSAGES } from 'app/shared/config/denial-messages.config';
import { JsonProperty } from 'json-object-mapper';
import { DAYS_UNTIL_RENEWAL_THRESHOLD } from 'src/app/core/config/subscription.config';
import { OpportunityStatus } from 'src/app/modules/widget/enums/opportunity-status.enum';
import { StatusEnum } from 'src/app/modules/widget/enums/status.enum';
import {
  Customer,
  PrimaryContact,
} from 'src/app/modules/widget/models/customer.model';
import { IncludedItem } from 'src/app/modules/widget/models/included-item.model';
import { Item } from 'src/app/modules/widget/models/item.model';
import { Opportunity } from 'src/app/modules/widget/models/opportunity.model';
import { Plan } from 'src/app/modules/widget/models/plan.model';
import { Term } from 'src/app/modules/widget/models/term.model';
import { SubscriptionOperationEnum } from '../enums/subscription-operation.enum';
import { Quote } from './quote.model';
import { Renewal } from './renewal.model';

const DEFAULT_DENIAL_MESSAGE =
  'WIDGET.SUBSCRIPTION_LIST.LABELS.DEFAULT_DENIAL_MESSAGE';
const PENDING_ACTIVATION_MESSAGE =
  'WIDGET.SUBSCRIPTION_LIST.LABELS.PENDING_ACTIVATION_MESSAGE';
const PROHIBITED_STATUSES_FOR_COMPLETE_RENEWAL = [
  OpportunityStatus.CLOSED_WON,
  OpportunityStatus.INACTIVE,
  OpportunityStatus.NON_RENEWING,
  OpportunityStatus.SUSPENDED,
];

export class Subscription {
  @JsonProperty() public ARR: string;
  /** can be auto-renewed */
  @JsonProperty() public autorenewal: boolean;
  /** customer */
  @JsonProperty({ type: Customer }) public customer: Customer;
  /** draft subscription */
  @JsonProperty() public draftRenewalSubscription?: Subscription;
  /** pending-activation subscription */
  @JsonProperty() public pendingActivationSubscription?: Subscription;
  /** customer */
  @JsonProperty({ type: Customer }) public endUser: Customer;
  /** id */
  @JsonProperty() public id: number;
  /** included items */
  @JsonProperty() public includedItems: IncludedItem[];
  /** subscription items */
  @JsonProperty() public items: Item[];
  /** parent subscription */
  @JsonProperty() public parentSubscription: number | string;
  /** subscription plan details */
  @JsonProperty() public plan: Plan;
  /** subscription status */
  @JsonProperty() public status: StatusEnum;
  /** subscription term */
  @JsonProperty({ type: Term }) public term: Term;
  /** total amount */
  @JsonProperty() public totalAmount: number;
  /** total subscription interval amount */
  @JsonProperty() public totalIntervalValue: number;
  /** currency */
  @JsonProperty() public currency: string;
  /** serial or license number */
  @JsonProperty() public serialOrLicenseNumber: string;
  /** operations */
  @JsonProperty() public operations: SubscriptionOperationEnum[];

  @JsonProperty() public priceReset?: boolean;
  @JsonProperty() public quotes?: Quote[];
  @JsonProperty() public operationDenialReasons: OptionDenialReason;
  @JsonProperty() public renewalPrimaryContact?: Partial<PrimaryContact>;
  @JsonProperty() public renewalOpportunity?: Opportunity;
  @JsonProperty() public upsellOpportunity?: Opportunity;

  @JsonProperty({ type: Renewal, name: 'renewal' })
  private renewalValue?: Renewal;

  public get isEverGreenSubscription(): boolean {
    // is evergreen if no end date available
    return !this.term?.end;
  }

  /** if quantity is returned by API */
  public get hasQuantity(): boolean {
    return this.includedItems.some(
      (item) => item.quantity !== undefined && item.quantity !== null
    );
  }

  get isRenewalPORequired(): boolean | null {
    return this.isPORequired(this.renewalOpportunity?.customerPOStatus);
  }

  get isUpsellPORequired(): boolean | null {
    return this.isPORequired(this.upsellOpportunity?.customerPOStatus);
  }

  public get getDenialMessage(): string {
    if (this.status === StatusEnum.PendingActivation) {
      return PENDING_ACTIVATION_MESSAGE;
    } else if (
      this.operationDenialReasons &&
      this.operationDenialReasons.renew in DENIAL_MESSAGES
    ) {
      // Return the custom message if available
      return DENIAL_MESSAGES[this.operationDenialReasons.renew as DenialReason];
    } else {
      // Default message if no specific custom message is available
      return DEFAULT_DENIAL_MESSAGE;
    }
  }

  /** if quantity is returned by API */
  public get hasPrices(): boolean {
    return this.items.some((item) => !!item.prices?.length);
  }

  public get hasQuantityAndPrices(): boolean {
    return this.hasQuantity && this.hasPrices;
  }

  public get hasAmount(): boolean {
    return this.totalAmount !== undefined && this.totalAmount !== null;
  }

  public get hasTotalIntervalValue(): boolean {
    return (
      this.totalIntervalValue !== undefined && this.totalIntervalValue !== null
    );
  }

  public get isTermedSubscription(): boolean {
    return !this.isEverGreenSubscription;
  }

  /** determine if there is a linked pending activation */
  public get hasPendingActivation(): boolean {
    return !!this.pendingActivationSubscription;
  }

  /** determine is subscription is trial subscription */
  public get isTrial(): boolean {
    return (
      (!(this.totalAmount > 0) && !isNaN(this.totalAmount)) ||
      (!(this.totalIntervalValue > 0) && !isNaN(this.totalIntervalValue))
    );
  }

  /** number of days until term end */
  public get daysUntilTermEnd(): number {
    if (this.isEverGreenSubscription) {
      return Number.POSITIVE_INFINITY;
    } else {
      const difference =
        new Date(this.term.end as string).getTime() - new Date().getTime();
      return Math.floor(difference / (1000 * 3600 * 24)) || 0;
    }
  }

  /** renewal message color */
  public get renewalMessageColor(): string {
    if (this.daysUntilTermEnd > DAYS_UNTIL_RENEWAL_THRESHOLD) {
      return 'k-text-dark';
    }
    return 'k-text-error';
  }

  /** status display name */
  public get statusName(): string {
    return Object.values(StatusEnum).includes(this.status)
      ? `WIDGET.SUBSCRIPTION_LIST.SUBSCRIPTION_STATUSES.${this.status}`
      : 'WIDGET.SUBSCRIPTION_LIST.SUBSCRIPTION_STATUSES.UNKNOWN';
  }

  /** kendo chip colors based on status */
  public get statusChip(): { themeColor: ChipThemeColor; icon: string } {
    switch (this.status) {
      case StatusEnum.Active:
        return { themeColor: 'success', icon: 'k-icon k-i-check-outline' };
      case StatusEnum.Closed:
        return { themeColor: 'error', icon: 'k-icon k-i-close-outline' };
      case StatusEnum.PendingActivation:
        return { themeColor: 'base', icon: 'k-icon k-i-clock' };
      case StatusEnum.Suspended:
        return { themeColor: 'warning', icon: 'k-icon k-i-warning' };
      default:
        return { themeColor: 'base', icon: 'k-icon k-i-minus-outline' };
    }
  }

  /** quantity of the required included items */
  public get requiredQuantity(): number {
    const requiredItem = this.items?.find((item) => item?.required);
    return (
      this.includedItems?.find((item) => item?.id === requiredItem?.id)
        ?.quantity ?? 0
    );
  }

  /** check if subscription can be downgraded */
  public get canDowngrade(): boolean {
    return this.isEverGreenSubscription || this.status === StatusEnum.Draft;
  }

  public get canModify(): boolean {
    return (
      this.hasOperation(SubscriptionOperationEnum.Upsell) &&
      this.isAllowedForModify
    );
  }

  public get canCompleteModification(): boolean {
    return (
      this.hasOperation(SubscriptionOperationEnum.CompleteUpsell) &&
      this.isAllowedForModify &&
      this.upsellQuotes.some((quote) => quote.status === QuoteStatus.SIGNED)
    );
  }

  public get canRenew(): boolean {
    return (
      this.isAllowedForRenew &&
      this.hasOperation(SubscriptionOperationEnum.Renew)
    );
  }

  get canCompleteRenewal(): boolean {
    const hasSignedRenewalQuote = this.renewalQuotes.some(
      (quote) => quote.status === QuoteStatus.SIGNED
    );
    const hasPendingActivation =
      this.operationDenialReasons.renew ===
      DenialReason.SubscriptionHasPendingActivation;
    const renewalStatus = this.renewalOpportunity?.status;
    return (
      this.isAllowedForRenew &&
      ((this.hasOperation(SubscriptionOperationEnum.CompleteRenewal) &&
        hasSignedRenewalQuote) ||
        hasPendingActivation) &&
      (renewalStatus
        ? !PROHIBITED_STATUSES_FOR_COMPLETE_RENEWAL.includes(renewalStatus)
        : true)
    );
  }

  public get planGroup(): SubscriptionPlanGroupEnum {
    const planCode = this.plan.code;
    if (!NON_LEGACY_SUBSCRIPTION_PLAN_REGEX.test(planCode)) {
      return SubscriptionPlanGroupEnum.LegacyPlan;
    } else if (SUPPORT_ONLY_SUBSCRIPTION_PLAN_REGEX.test(planCode)) {
      return SubscriptionPlanGroupEnum.SupportOnlyPlan;
    } else {
      return SubscriptionPlanGroupEnum.NewPlan;
    }
  }

  get isCustomerEqualEndUser() {
    return this.customer.id === this.endUser.id;
  }

  get renewal(): Renewal {
    if (this.renewalValue) {
      return this.renewalValue;
    } else {
      const renewal = new Renewal();
      renewal.supportLevel = this.plan.supportLevel;
      renewal.productTier = this.plan.productTier;
      renewal.productTierTitle = this.plan.productTierTitle;
      renewal.enableItemsQuantityEdit = this.plan.enableItemsQuantityEdit;
      return renewal;
    }
  }

  get renewalQuotes(): Quote[] {
    return (
      this.quotes?.filter(
        (quote) =>
          !!this.renewalOpportunity &&
          quote.opportunityId === this.renewalOpportunity.id
      ) ?? []
    );
  }

  get upsellQuotes(): Quote[] {
    return (
      this.quotes?.filter(
        (quote) =>
          !!this.upsellOpportunity &&
          quote.opportunityId === this.upsellOpportunity.id
      ) ?? []
    );
  }

  private get isAllowedForModify(): boolean {
    const hasPositiveARR = Number(this.ARR) > 0;
    const canEditQuantity = this.plan?.enableItemsQuantityEdit;
    const hasEditableItems = this.items.some((item) => item.enableQuantityEdit);
    const hasSignedRenewalQuote = this.renewalQuotes.some(
      (quote) => quote.status === QuoteStatus.SIGNED
    );
    const hasClosedWonRenewalOpportunity =
      this.renewalOpportunity?.status === OpportunityStatus.CLOSED_WON;

    return (
      hasPositiveARR &&
      canEditQuantity &&
      hasEditableItems &&
      !hasSignedRenewalQuote &&
      !hasClosedWonRenewalOpportunity
    );
  }

  private get isAllowedForRenew(): boolean {
    return !!this.renewalOpportunity?.id;
  }

  private hasOperation(operation: SubscriptionOperationEnum): boolean {
    return !!this.operations?.includes(operation);
  }

  private isPORequired(customerPOStatus?: string | null): boolean | null {
    switch (customerPOStatus) {
      case CustomerPOStatus.RequiredPending:
      case CustomerPOStatus.RequiredAttached:
        return true;
      case CustomerPOStatus.NotRequired:
        return false;
      case CustomerPOStatus.Empty:
      case null:
        return null;
      default:
        return null;
    }
  }

  constructor() {
    this.ARR = null;
    this.id = null;
    this.status = null;
    this.customer = null;
    this.endUser = null;
    this.plan = null;
    this.items = null;
    this.includedItems = null;
    this.term = null;
    this.autorenewal = null;
    this.totalAmount = undefined;
    this.parentSubscription = null;
    this.draftRenewalSubscription = null;
    this.pendingActivationSubscription = null;
    this.operations = null;
    this.currency = 'USD';
    this.totalIntervalValue = undefined;
    this.priceReset = null;
    this.serialOrLicenseNumber = null;
    this.quotes = [];
    this.operationDenialReasons = null;
    this.renewalPrimaryContact = null;
    this.renewalOpportunity = null;
    this.upsellOpportunity = null;
    this.renewalValue = null;
  }
}
