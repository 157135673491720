import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InitializationService } from 'src/app/core/services/initialization.service';
import { EditionEnum } from 'src/app/modules/widget/enums/edition.enum';
import { SuccessPlanEnum } from 'src/app/modules/widget/enums/success-plan.enum';
import { SubscriptionConfig } from 'src/app/modules/widget/models/subscription-config.model';
import { Subscription } from 'src/app/modules/widget/models/subscription.model';
import { SubscriptionState } from 'src/app/modules/widget/states/subscription.state';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionFacade {
  /** subscriptions list */
  public subscriptions$ = this.state.subscriptions$;
  /** subscription plans list */
  public subscriptionPlans$ = this.state.groupedSubscriptionPlans$;
  /** selected subscription plan config */
  public selectedPlanConfig$ = this.state.selectedPlanConfig$;
  /** selected subscription plan config */
  public selectedPlanCode$ = this.state.selectedPlanConfig$.pipe(
    map((config) => this.state.getPlanCode(config))
  );
  /** selected subscription plan title */
  public selectedPlanTitle$ = this.state.selectedPlanConfig$.pipe(
    map((config) => this.state.getPlanTitle(config))
  );
  /** selected subscription plan */
  public selectedSubscriptionPlan$ = this.state.selectedPlanConfig$.pipe(
    map((config) => this.state.getSubscriptionPlanFromConfig(config))
  );
  /** Represents the error state as error message obtained from endpoint */
  public error$ = this.state.error$;
  /** Whether there is any error thrown by endpoint or not. */
  public hasError$ = this.state.error$.pipe(map((error) => !!error));
  /** Whether the state is ready or not to use. */
  public isReady$ = this.state.isReady$;
  /** Represents whether endpoint is retrying to fetch the data. */
  public isRetrying$ = this.state.isRetrying$;
  /** editions list */
  public editions$ = this.state.editions$;
  /** success plans */
  public successPlans$ = this.state.successPlans$;
  /** billing frequencies */
  public billingFrequencies$ = this.state.billingFrequencies$;
  /** signed-in token customer id */
  public tokenCustomerId = this.initializationService.tokenCustomerId;

  public subscription = this.state.getSubscriptionById.bind(this.state);

  public allSubscriptionPlans = this.state.getAllSubscriptionPlans.bind(
    this.state
  );

  public openRenewalDetailsOrNavigateHome =
    this.state.openRenewalDetailsOrNavigateHome.bind(this.state);

  constructor(
    private readonly state: SubscriptionState,
    private readonly initializationService: InitializationService
  ) {}

  /** Reset all states to avoid any memory leak on destruction of component. */
  public reset = this.state.reset.bind(this.state);
  /** To be used the re-trigger the endpoint api call */
  public retry = this.state.retry.bind(this.state);
  /** To get subscriptions and plans */
  public getRecords = this.state.getRecords.bind(this.state);
  /** Get Plan from subscription */
  public getSubscriptionPlanFromSubscription =
    this.state.getSubscriptionPlanFromSubscription.bind(this.state);
  /** get billing frequencies */
  public getBillingFrequencies = this.state.getBillingFrequencies.bind(
    this.state
  );

  /** set subscription plan config */
  public setPlanConfig(subscription?: Subscription): void {
    this.state.setPlanConfig(subscription);
  }

  /** set alternative edition name */
  public getAlternativeEditionName(edition: EditionEnum): string {
    return this.state.getAlternativeEditionName(edition);
  }

  /**
   * update subscription plan config
   * in subscription form
   */
  public updatePlanConfig(
    config: SubscriptionConfig,
    filterSuccessPlan?: SuccessPlanEnum
  ): void {
    this.state.updatePlanConfig(config, filterSuccessPlan);
  }
}
