import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { CustomerInformation } from '../../models';

import * as CustomerActions from './customer.actions';

export const CUSTOMER_FEATURE_KEY = 'customer';

export interface State extends EntityState<Partial<CustomerInformation>> {
  error?: string | null;
  loading: boolean;
  loaded: boolean;
  selectedId: number | string;
  savedSuccessfully?: boolean;
  shippingAddressSavedSuccessfully?: boolean;
  customerDataSavedSuccessfully?: boolean;
}

export interface CustomerPartialState {
  readonly [CUSTOMER_FEATURE_KEY]: State;
}

export const customerAdapter: EntityAdapter<Partial<CustomerInformation>> =
  createEntityAdapter<Partial<CustomerInformation>>();

export const initialState: State = customerAdapter.getInitialState({
  loading: true,
  loaded: false,
  selectedId: null,
  savedSuccessfully: false,
  shippingAddressSavedSuccessfully: null,
  customerDataSavedSuccessfully: null,
});

const customerReducer = createReducer(
  initialState,
  on(CustomerActions.init, CustomerActions.save, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(
    CustomerActions.loadCustomerSuccess,
    CustomerActions.updateCustomerSuccess,
    (state, { customer }) =>
      customerAdapter.setAll(customer, {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        selectedId: customer[0].id,
      })
  ),
  on(CustomerActions.saveShippingAddressSuccess, (state) => ({
    ...state,
    shippingAddressSavedSuccessfully: true,
  })),
  on(CustomerActions.saveCustomerDataSuccess, (state) => ({
    ...state,
    customerDataSavedSuccessfully: true,
  })),
  on(CustomerActions.resetShippingAddressSaveStatus, (state) => ({
    ...state,
    shippingAddressSavedSuccessfully: null,
  })),
  on(CustomerActions.resetCustomerDataSaveStatus, (state) => ({
    ...state,
    customerDataSavedSuccessfully: null,
  })),

  on(CustomerActions.resetSaveStatus, (state) => ({
    ...state,
    savedSuccessfully: false,
  })),
  on(CustomerActions.resetError, (state) => ({
    ...state,
    error: null,
  })),

  on(CustomerActions.updateCustomerSuccess, (state) => ({
    ...state,
    savedSuccessfully: true,
  })),

  on(CustomerActions.loadCustomerFailure, (state) => ({
    ...state,
    savedSuccessfully: false,
  })),

  on(
    CustomerActions.loadCustomerFailure,
    CustomerActions.updateCustomerFailure,
    (state, { error }) => ({
      ...state,
      error,
      loaded: true,
      loading: false,
    })
  ),
  on(CustomerActions.reset, (state) => ({ ...state, ...initialState })),
  on(CustomerActions.saveCustomerDataFailure, (state, { error }) => ({
    ...state,
    error: error,
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return customerReducer(state, action);
}
