<ng-container *ngIf="stepperFacade.documentsLoadingError$ | async; else LegalNameChangeContent">
  <app-error (retry)="onRetry()"></app-error>
</ng-container>

<ng-template #LegalNameChangeContent>
  <div class="h-100 k-d-flex k-flex-column">
    <div class="k-mb-4">
      <app-notification [notificationTitle]="title" [notificationDescription]="infoText"></app-notification>
    </div>
    <div class="w-100 h-100 k-d-flex k-justify-content-between k-gap-4 k-pb-4" #containerForNotification>
      <div class="file-upload k-flex-1">
        <app-file-upload [activeSubscription]="activeSubscription"
                         [dropdownMenu]="dropdownMenu"
                         [draftSubscription]="draftSubscription"
                         [notificationContainer]="notificationContainer"
                         [showDocumentTypes]="true"
                         [showPo]="!hidePo"></app-file-upload>
      </div>

      <div class="file-upload-table">
        <app-uploaded-files-table [activeSubscription]="activeSubscription"
                                  [draftSubscription]="draftSubscription"
                                  [isFinalizeMode]="isFinalizeMode"
                                  [notificationContainer]="notificationContainer"
                                  (uploadedFiles$)="onFilesEmited($event)"></app-uploaded-files-table>
      </div>
    </div>

    <div *ngIf="isFinalizeMode" class="finalize-container" [class.with-padding]="isInlineUi">
      <button kendoButton (click)="back()">{{ 'SHARED.ACTIONS.BACK' | translate }}</button>
      <div kendoTooltip
           class="w-auto"
           [title]="isFinalizeButtonDisabled ?
           (tooltipText | translate) : ''">
        <button
          kendoButton
          themeColor="primary"
          [disabled]="isFinalizeButtonDisabled"
          (click)="closeOpportunity()"
        >
          {{ 'WIDGET.DOCUMENTS_MODAL.ACTIONS.FINALIZE' | translate }}

          <kendo-loader
            *ngIf="isFinalizeButtonLoading"
            [type]="'infinite-spinner'"
            themeColor="light"
            [size]="'small'"
          >
          </kendo-loader>
        </button>
      </div>
    </div>
  </div>

</ng-template>
