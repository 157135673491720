import { Injectable } from '@angular/core';
import { CustomerInformation } from '../models/customer-information.model';
import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AuthHttpClient } from 'app/core/http/services/auth.http.client';
import { environment } from 'src/environments/environment';

const API_URL = `${environment.apiUrl}customer`;

@Injectable()
export class CustomerService {
  constructor(private readonly httpClient: AuthHttpClient) {}

  /** Get customer information */
  public getCustomerInformation(): Observable<CustomerInformation> {
    return this.httpClient
      .get(API_URL)
      .pipe(
        map((response) =>
          ObjectMapper.deserialize(CustomerInformation, response)
        )
      );
  }

  /** Update customer information */
  public updateCustomerInformation(
    information: Partial<CustomerInformation>
  ): Observable<string> {
    return this.httpClient.patch(API_URL, information, {
      responseType: 'text',
    });
  }
}
