import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CustomerInformation } from '@tsi/tsi/customer';
import { NotificationFacade } from 'app/modules/widget/facades/notification.facade';
import { of } from 'rxjs';
import { CUSTOMER_SUCCESS_MESSAGE } from '../../constants/notification.message';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CustomerService } from '../../services/customer.service';

import * as CustomerActions from './customer.actions';

type ActionCreatorWithPayload<T = any> = (payload: T) => Action;

@Injectable()
export class CustomerEffects {
  private handleCustomerUpdate(
    payload: Partial<CustomerInformation>,
    successAction: Action,
    failureAction: ActionCreatorWithPayload<{ error: string }>,
    showNotification: boolean = true
  ) {
    return this.service.updateCustomerInformation(payload).pipe(
      map(() => {
        if (showNotification) {
          this.notification.successNotification(CUSTOMER_SUCCESS_MESSAGE);
        }
        return successAction;
      }),
      catchError((e: HttpErrorResponse) => {
        const error = JSON.parse(e.error);
        this.notification.errorNotification(error?.errors[0]?.message);
        return of(
          failureAction({
            error: e.error.message ?? e.message,
          })
        );
      })
    );
  }

  public init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.init),
      fetch({
        run: () => {
          return this.service
            .getCustomerInformation()
            .pipe(
              map((customer) =>
                CustomerActions.loadCustomerSuccess({ customer: [customer] })
              )
            );
        },
        onError: (_, error: HttpErrorResponse) => {
          return CustomerActions.loadCustomerFailure({
            error: error.error.message ?? error.message,
          });
        },
      })
    )
  );

  public save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.save),
      mergeMap((action) =>
        this.handleCustomerUpdate(
          action.customer,
          CustomerActions.updateCustomerSuccess({
            // @ts-ignore
            customer: [action.customer],
          }),
          CustomerActions.saveCustomerDataFailure,
          action.showNotification
        )
      )
    )
  );

  public saveShippingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.saveShippingAddress),
      mergeMap((action) =>
        this.handleCustomerUpdate(
          { id: action.endUserId, shippingAddress: action.shippingAddress },
          CustomerActions.saveShippingAddressSuccess(),
          CustomerActions.saveCustomerDataFailure,
          action.showNotification
        )
      )
    )
  );

  public saveCustomerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.saveCustomerData),
      mergeMap((action) =>
        this.handleCustomerUpdate(
          { id: action.customerId, ...action.customerData },
          CustomerActions.saveCustomerDataSuccess(),
          CustomerActions.saveCustomerDataFailure,
          action.showNotification
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: CustomerService,
    private readonly notification: NotificationFacade
  ) {}
}
