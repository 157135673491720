import { JsonProperty } from 'json-object-mapper';
import { PreviewPricingItem } from './preview-pricing-item.model';

export class PreviewForModifyResponse {
  @JsonProperty() public currentARR: number;
  @JsonProperty() public duePayment: number;
  @JsonProperty() public frequency: string;
  @JsonProperty() public futureARR: number;
  @JsonProperty() public items: PreviewPricingItem[];
  @JsonProperty() public quoteTotalAmount: number;
  @JsonProperty() public totalListPrice: number;
  @JsonProperty() public upsellARR: number;

  constructor() {
    this.currentARR = 0;
    this.duePayment = 0;
    this.frequency = null;
    this.futureARR = 0;
    this.items = [];
    this.quoteTotalAmount = 0;
    this.totalListPrice = 0;
    this.upsellARR = 0;
  }
}
