import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationModule} from '@progress/kendo-angular-notification';
import {RenewalContactService} from 'libs/tsi/customer/src/lib/services/renewal-contact.service';
import {CustomerService} from './services/customer.service';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromCustomer from './+state/customer/customer.reducer';
import {CustomerEffects} from './+state/customer/customer.effects';
import {CustomerFacade} from './+state/customer/customer.facade';
import * as fromRenewalContact from './+state/renewal-contact/renewal-contact.reducer';
import {RenewalContactEffects} from './+state/renewal-contact/renewal-contact.effects';
import {RenewalContactFacade} from './+state/renewal-contact/renewal-contact.facade';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(fromCustomer.CUSTOMER_FEATURE_KEY, fromCustomer.reducer),
    EffectsModule.forFeature([CustomerEffects]),
    StoreModule.forFeature(fromRenewalContact.RENEWAL_CONTACT_FEATURE_KEY, fromRenewalContact.reducer),
    EffectsModule.forFeature([RenewalContactEffects]), NotificationModule],
  providers: [CustomerService, CustomerEffects, CustomerFacade, RenewalContactFacade, RenewalContactService,
    RenewalContactEffects]
})
export class TsiCustomerModule {}
