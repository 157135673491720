import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CUSTOMER_FEATURE_KEY,
  State,
  customerAdapter,
} from './customer.reducer';

export const getCustomerState =
  createFeatureSelector<State>(CUSTOMER_FEATURE_KEY);

export const getShippingAddressSavedSuccessfully = createSelector(
  getCustomerState,
  (state: State) => state.shippingAddressSavedSuccessfully
);

export const getCustomerDataSavedSuccessfully = createSelector(
  getCustomerState,
  (state: State) => state.customerDataSavedSuccessfully
);

export const getSaveCustomerDataError = createSelector(
  getCustomerState,
  (state) => state.error
);

const { selectAll, selectEntities } = customerAdapter.getSelectors();

export const getCustomerLoaded = createSelector(
  getCustomerState,
  (state: State) => state.loaded
);

export const getCustomerLoading = createSelector(
  getCustomerState,
  (state: State) => state.loading
);

export const getCustomerError = createSelector(
  getCustomerState,
  (state: State) => state.error
);

export const hasCustomerError = createSelector(
  getCustomerError,
  (error: string) => !!error
);

export const getAllCustomer = createSelector(getCustomerState, (state: State) =>
  selectAll(state)
);

export const getCustomerEntities = createSelector(
  getCustomerState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getCustomerState,
  (state: State) => state.selectedId
);

export const isCustomerSavedSuccessfully = createSelector(
  getCustomerState,
  (state: State) => state.savedSuccessfully
);

export const getSelected = createSelector(
  getCustomerEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
