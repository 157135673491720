import { registerLocaleData } from '@angular/common';
import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { load } from '@progress/kendo-angular-intl';
import { L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import * as Sentry from '@sentry/angular';
import { CustomErrorHandlerService } from 'app/core/services/custom-error-handler.service';
import { UiStorageService } from 'app/core/services/ui-storage.service';
import { provideLottieOptions } from 'ngx-lottie';
import { CoreModule } from 'src/app/core/core.module';
import { loadConfigProvider } from 'src/app/core/factories/initializer.factory';
import { environment } from '../environments/environment';
import { appInitializeFactory } from './app-initialize.factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BUService } from './core/services/bu-service';
import { KENDO_LOCALES_MAP, Language, LOCALES_MAP } from './translate';
import { CustomMissingTranslationHandler } from './translate/utils/missing-translation-handler';

LOCALES_MAP.forEach((localeData) => {
  registerLocaleData(localeData);
});
KENDO_LOCALES_MAP.forEach((localeData) => {
  load(localeData);
});

@NgModule({
  bootstrap: [AppComponent], declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DialogModule,
    CoreModule,
    NotificationModule,
    TooltipsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    }),
    StoreModule.forRoot({}, {
      metaReducers: !environment.production ? [] : [], runtimeChecks: {
        strictActionImmutability: true, strictStateImmutability: true
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    { provide: Sentry.TraceService, deps: [Router]},
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializeFactory,
      deps: [BUService, HttpBackend, TranslateService, UiStorageService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: Language.English },
    { provide: LOCALE_ID, useValue: Language.German },
    { provide: L10N_PREFIX, useValue: 'kendo' },
    loadConfigProvider,
    provideLottieOptions({
      player: () => import('lottie-web')
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {}
