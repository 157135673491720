import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AgreementStatusEnum } from 'app/modules/widget/enums/agreement-status.enum';
import { PrimeQuestionsButtonsEnum } from 'app/modules/widget/enums/prime-questions-buttons.enum';
import { ResponseStatusEnum } from 'app/modules/widget/enums/response-status.enum';
import { StatusEnum } from 'app/modules/widget/enums/status.enum';
import { StepperLabelEnum } from 'app/modules/widget/enums/stepper-label.enum';
import { SuccessPlanEnum } from 'app/modules/widget/enums/success-plan.enum';
import { NotificationFacade } from 'app/modules/widget/facades/notification.facade';
import { EditionContent } from 'app/modules/widget/models/edition-content.model';
import { Item } from 'app/modules/widget/models/item.model';
import { PreviewForRenewalRequest } from 'app/modules/widget/models/preview-for-renewal-request.model';
import { PreviewForRenewalResponse } from 'app/modules/widget/models/preview-for-renewal-response.model';
import { PageSample } from 'app/modules/widget/models/prime-content.model';
import { QuoteRequest } from 'app/modules/widget/models/quote-request.model';
import { Quote } from 'app/modules/widget/models/quote.model';
import {
  RenewalPlan, RenewalResponse
} from 'app/modules/widget/models/renewal-response.model';
import { SubscriptionPlan } from 'app/modules/widget/models/subscription-plan.model';
import { Subscription } from 'app/modules/widget/models/subscription.model';
import { SuccessContentModel } from 'app/modules/widget/models/success-content.model';
import {
  ErrorObject, UploadResponse
} from 'app/modules/widget/models/upload-response.model';
import { RenewalFlowService } from 'app/modules/widget/services/renewal-flow.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  catchError, distinctUntilChanged, filter, finalize, map, switchMap, take, tap
} from 'rxjs/operators';
import { UiStorageService } from '../../../core/services';
import { IncludedItem } from '../models/included-item.model';
import { SubscriptionService } from '../services/subscription.service';
import { HelperMethods } from '../utils/helper-methods';
import { UiStorageKey } from '../../../core/enums/ui-storage-key.enum';

export interface StepperState {
  renewalStatus: AgreementStatusEnum;
  productEdition: string;
  successLevel: string;
  tailoredPrice: number;
  priceLockedIn: number;
  primeProducts: Partial<PageSample>[];
  currentStep: number;
  totalContractAmount?: number;
  planCode?: string;
  plan: RenewalPlan;
  awsPrimeAnswer?: PrimeQuestionsButtonsEnum;
  crossoverPrimeAnswer?: PrimeQuestionsButtonsEnum;
  renewalComplete?: boolean;
  primeInterests: string[];
  items: Item[];
  discountPercentage?: number;
  renewalTimestamp?: number;
  previewResponse: PreviewForRenewalResponse;
}

export interface Multipliers {
  [SuccessPlanEnum.Silver]: number;
  [SuccessPlanEnum.Gold]: number;
  [SuccessPlanEnum.Platinum]: number;
}

@Injectable({
  providedIn: 'root',
})
export class RenewalState {
  private state: StepperState;
  private stateSubject = new BehaviorSubject<StepperState | null>(null);
  private _loader = new BehaviorSubject(false);
  private _guoteLoader = new BehaviorSubject(false);
  private _error = new BehaviorSubject<boolean | ErrorObject>(false);
  public loader$ = this._loader.asObservable().pipe(distinctUntilChanged());
  public quoteLoader$ = this._guoteLoader
    .asObservable()
    .pipe(distinctUntilChanged());
  public error$       = this._error.asObservable().pipe(distinctUntilChanged());
  public uploadState  = new BehaviorSubject<{
    status: ResponseStatusEnum; errorMessage?: string;
    loading: boolean;
    response?: UploadResponse;
  }>({status: ResponseStatusEnum.Idle, loading: false, errorMessage: null});
  public uploadState$ = this.uploadState.asObservable();
  private _finalizeErrorMessages = new BehaviorSubject<string[]>([]);
  public finalizeErrorMessages$ = this._finalizeErrorMessages.asObservable();

  constructor(
    private readonly _renewalFlowService: RenewalFlowService,
    private readonly _notificationFacade: NotificationFacade,
    private readonly _subscriptionService: SubscriptionService,
    private readonly translate: TranslateService,
    private readonly uiStorageService: UiStorageService,
  ) {}

  public setQuoteLoader(loading: boolean) {
    this._guoteLoader.next(loading);
  }

  public setLoader(loading: boolean) {
    this._loader.next(loading);
  }

  public setSuccessLevel(successLevel: string, opportunity: number) {
    this.state.successLevel = successLevel;
    this.saveState(opportunity);
  }

  public setTimestamp(timestamp: number, opportunity: number) {
    this.state.renewalTimestamp = timestamp;
    this.saveState(opportunity);
  }

  public setAwsPrimeAnswer(
    awsPrimeAnswer: PrimeQuestionsButtonsEnum, opportunity: number
  ) {
    this.state.awsPrimeAnswer = awsPrimeAnswer;
    this.saveState(opportunity);
  }

  public setCrossoverPrimeAnswer(
    crossoverPrimeAnswer: PrimeQuestionsButtonsEnum, opportunity: number
  ) {
    this.state.crossoverPrimeAnswer = crossoverPrimeAnswer;
    this.saveState(opportunity);
  }

  public setPrice(
    priceLockedIn: number,
    tailoredPrice: number, opportunity: number
  ) {
    this.state.priceLockedIn = priceLockedIn;
    this.state.tailoredPrice = tailoredPrice;
    this.saveState(opportunity);
  }

  public setError(error: boolean | ErrorObject) {
    this._error.next(error);
  }

  public setPrimeProducts(
    primeProduct: Partial<PageSample>, opportunity: number
  ) {
    // Check if the product already exists in the array
    const productExists = this.state.primeProducts.some(
      (product) => product.productName === primeProduct.productName
    );

    const product: Partial<PageSample> = {
      productName: primeProduct.productName,
      logo: primeProduct.featureGrid.logo,
      productColor: primeProduct.productColor,
    };

    // If the product doesn't exist, add it to the array
    if (!productExists) {
      this.state.primeProducts.push(product);
      this.saveState(opportunity);
    }
  }

  public setProductInterests(primeInterests: string[], opportunity: number) {
    // Create a Set from the existing primeInterests to remove duplicates
    const uniquePrimeInterests = new Set(primeInterests);
    this.state.primeInterests = [...uniquePrimeInterests];
    this.saveState(opportunity);
  }

  public setDiscountPercentage(discount: number, opportunity: number) {
    this.state.discountPercentage = discount;
    this.saveState(opportunity);
  }

  public clearPrimeProducts(opportunity: number) {
    this.state.primeProducts = [];
    this.saveState(opportunity);
  }

  public removePrimeProduct(
    primeProduct: Partial<PageSample>, opportunity: number
  ) {
    this.state.primeProducts = this.state.primeProducts.filter(
      (product) => product.productName !== primeProduct.productName
    );
    this.saveState(opportunity);
  }

  public setCurrentStep(currentStep: number, opportunity: number) {
    this.state.currentStep = currentStep;
    this.saveState(opportunity);
  }

  public setProductEdition(productEdition: string, opportunity: number) {
    this.state.productEdition = productEdition;
    this.saveState(opportunity);
  }

  public setItemsAndPlan(response: RenewalResponse, parentSubscription: Subscription) {
    this.state.plan = response.plan;
    this.setItems(response.items, parentSubscription);
  }

  public setItems(items: Item[] | IncludedItem[], parentSubscription: Subscription): void {
    this.state.items = items.map((item: any) => {
      const subscriptionItem = parentSubscription.items.find(itemData => itemData.code === item.code);
      // If minQuantity is not set, initialize it with the current quantity
      return {
        ...item,
        originalQuantity: item.originalQuantity ? item.originalQuantity : null,
        minQuantity: item.minQuantity !== undefined ? item.minQuantity : item.quantity,
        enableQuantityEdit: typeof item.enableQuantityEdit === 'boolean' ?
          item.enableQuantityEdit : subscriptionItem?.enableQuantityEdit,
      };
    });
    this.saveState(parentSubscription.renewalOpportunity.id);
  }

  public setRenewalComplete(renewalComplete: boolean, opportunity: number) {
    this.state.renewalComplete = renewalComplete;
    this.saveState(opportunity);
  }

  private saveState(opportunity: number) {
    this.setStepperState(this.state, opportunity).subscribe();
  }

  /**
   * Loads the state of a subscription, updating the state subject with the new state.
   * This method combines data from different sources (wizard step and agreement status)
   * and processes it to set the appropriate state for the stepper.
   *
   * @param {Subscription} subscription - The subscription for which the state is being loaded.
   * @param {Subscription} parentSubscription - The parent subscription associated with the current subscription.
   * @param {SubscriptionPlan} subscriptionPlan - The subscription plan associated with the subscription.
   * @param {successConfig} successConfig - Success content configuration.
   *
   * @returns {void} This method does not return a value. It updates the state subject with the new state.
   *
   * @description
   * The method begins by setting the loading state to true. It then combines the results from
   * `getStepperState` and `getAgreementStatus` methods of `_renewalFlowService`. The combined
   * result is processed to determine the appropriate state. If the storage response is empty,
   * a default state is used. The state includes renewal status, prime interests, and other
   * relevant properties. The state is then emitted through `stateSubject`. In case of an error,
   * the error state is set and the default state is emitted. Finally, the method sets the loading
   * state to false and performs additional processing based on the `primeServices` and the
   * `productEdition`.
   */
  public loadState(
    subscription: Subscription, parentSubscription: Subscription, subscriptionPlan: SubscriptionPlan,
    successConfig: SuccessContentModel, editionContent: EditionContent[]
  ): void {
    this._loader.next(true);

    combineLatest([
      this.getStepperState(parentSubscription.renewalOpportunity.id),
      this._renewalFlowService.getAgreementStatus(parentSubscription.renewalOpportunity.id)
    ])
      .pipe(
        map(([storageResponse, agreementStatus]) => {
          const storage =
            Object.keys(storageResponse).length === 0
              ? this.getDefaultState()
              : storageResponse;

          return {
            ...storage, renewalStatus: subscription ? agreementStatus : AgreementStatusEnum.Signed,
            primeInterests: storageResponse.primeInterests || [],
          };
        }),
        tap((state: StepperState) => this.stateSubject.next(state)),
        catchError((_) => {
          this.setError(_);
          this.stateSubject.next(this.getDefaultState());
          return of(null);
        }),
        finalize(() => this._loader.next(false))
      )
      .subscribe((state) => {
        this.state = state;
        const primeServices = successConfig?.primeServices?.find(
          (item) => item?.name === subscriptionPlan?.product?.family?.code
        );

        this.setError(false);
        let productTierToSet = '';
        // Attempt to retrieve the product edition from the state, if available.
        const productTiersFromState = state.productEdition
          ? [state.productEdition]
          : [];

        const productTiersFromConfig: string[] = editionContent[0]?.productTiers || [];

        // If there's a product tier defined in the state, use it.
        if (productTiersFromState.length) {
          console.log('Using product tier from state:', productTiersFromState[0]);
          productTierToSet = productTiersFromState[0];
        } else {
          // Determine the product tier based on the number of tiers available in the configuration.
          if (productTiersFromConfig.length > 1) {
            // More than one tier available, use the tier from the parent subscription's plan.
            console.log(
              'Multiple product tiers available in config. Using parent subscription\'s product tier:',
              parentSubscription.renewal.productTier
            );
            productTierToSet = parentSubscription.renewal.productTier;
          } else if (productTiersFromConfig.length === 1) {
            // Only one tier available, use this tier.
            console.log('Single product tier available in config. Using:', productTiersFromConfig[0]);
            productTierToSet = productTiersFromConfig[0];
          } else if (productTiersFromConfig.length === 0) {
            console.log(
              'No product tiers in config. Using parent subscription\'s product tier:',
              parentSubscription.renewal.productTier
            );
            productTierToSet = parentSubscription.renewal.productTier;
          }
        }

        // If we've determined a product tier to set, apply it.
        if (productTierToSet) {
          this.setProductEdition(productTierToSet, parentSubscription.renewalOpportunity.id);
        }

        if (successConfig && primeServices && primeServices.skipSuccess && subscriptionPlan) {
          console.log('Using success level to Standard because skip success is true');
          this.setSuccessLevel(
            SuccessPlanEnum.Standard, parentSubscription.renewalOpportunity.id
          );
        } else if (!state.successLevel) {
          console.log('Using success level from parent subscription:', parentSubscription.renewal.supportLevel);
          this.setSuccessLevel(parentSubscription.renewal.supportLevel, parentSubscription.renewalOpportunity.id);
        } else {
          console.log('Using success level from state:', state.successLevel);
        }

        if (state.items) {
          this.state.items.forEach(item => {
            const subscriptionItem = parentSubscription.items.find(itemData => itemData.code === item.code);
            if (subscriptionItem) {
              item.enableQuantityEdit = subscriptionItem.enableQuantityEdit;
            }
          });
        }
      });
  }

  private getDefaultState(): StepperState {
    return {
      renewalStatus: null,
      productEdition: '',
      successLevel: '',
      tailoredPrice: 0,
      priceLockedIn: 0,
      primeProducts: [],
      currentStep: 0,
      totalContractAmount: 0,
      plan: null,
      awsPrimeAnswer: null,
      crossoverPrimeAnswer: null, renewalComplete: false,
      primeInterests: [],
      items: [],
      renewalTimestamp: null,
      previewResponse: null,
    };
  }

  public getState(): Observable<StepperState> {
    return this.stateSubject
      .asObservable()
      .pipe(filter((state) => state !== null));
  }

  public getCurrentStep(
    state: StepperState,
    steps: { label: string }[], subscription: Subscription
  ): number {
    const findStepIndex = (label: StepperLabelEnum) => steps.findIndex((step) => step.label === label);

    if (subscription.status !== StatusEnum.Draft) {
      return findStepIndex(StepperLabelEnum.Finalize);
    }

    const statusToStepMap: Record<AgreementStatusEnum, StepperLabelEnum> = {
      [AgreementStatusEnum.No_quote]: null, [AgreementStatusEnum.No_agreement]: StepperLabelEnum.Quotes,
      [AgreementStatusEnum.Draft]: StepperLabelEnum.Quotes,
      [AgreementStatusEnum.Out_for_signature]: StepperLabelEnum.Quotes,
      [AgreementStatusEnum.Signed]: StepperLabelEnum.Finalize
    };

    return statusToStepMap[state.renewalStatus] ? findStepIndex(statusToStepMap[state.renewalStatus]) :
           steps[state.currentStep].label !== StepperLabelEnum.Quotes && steps[state.currentStep].label !==
           StepperLabelEnum.Finalize ? state.currentStep : findStepIndex(StepperLabelEnum.Confirmation);

  }

  createQuote(requestBody: QuoteRequest, parentSubscription: Subscription): Observable<{ id: number }> {
    const planCode = this.state.plan.name || this.state.planCode;
    let planCode$: Observable<string>
    if (planCode) {
      planCode$ = of(planCode);
    } else {
      console.warn('Requesting renewal data because planCode is not found in state');
      planCode$ = this._renewalFlowService
        .getThePlanAndItems(
          String(parentSubscription.id),
          parentSubscription.renewal.productTier,
          parentSubscription.renewal.supportLevel
        ).pipe(map((response: RenewalResponse) => response.plan.name));
    }
    return planCode$.pipe(
      take(1),
      switchMap((planCode) => {
        const body: QuoteRequest = {
          ...requestBody,
          planCode,
          items: HelperMethods.formatItemsForCreatingQuote(this.state.items),
        };
        return this._renewalFlowService.createQuoteForSelfServe(body);
      })
    );
  }

  renewalIntent(subscriptionId: string) {
    return this._renewalFlowService.renewalIntent(subscriptionId);
  }

  /**
   * Retrieves a preview response for a subscription plan update.
   *
   * @param {string} productTier - The current product tier.
   * @param {string} supportLevel - The support level for the plan.
   * @param {PreviewForRenewalRequest} requestBody - Additional request data for the preview.
   * @param {Subscription} subscription - The draft subscription.
   * @param {Subscription} parentSubscription - The active subscription.
   * @param {Items} items - The plan items.
   * @returns {Observable<PreviewForRenewalResponse>} An Observable that emits the preview response.
   */
  getPreview(
    productTier: string,
    supportLevel: string,
    requestBody: PreviewForRenewalRequest,
    subscription: Subscription,
    parentSubscription: Subscription,
    items?: Item[]
  ): Observable<PreviewForRenewalResponse> {
    return this._renewalFlowService
      .getThePlanAndItems(
        String(parentSubscription.id),
        productTier,
        supportLevel
      )
      .pipe(
        switchMap((response: RenewalResponse) => {
          this.setItemsAndPlan(response, parentSubscription);
          if (items) {
            this.setItemsAndPlan({ ...response, items }, parentSubscription);
          } else {
            this.setItemsAndPlan(response, parentSubscription);
          }
          const body: PreviewForRenewalRequest = {
            planCode: response?.plan?.name,
            items: HelperMethods.formatItemsForPreviewRequest(items ?? response?.items),
            ...requestBody,
          };
          return this._subscriptionService
            .getPreviewForRenewal(body, String(subscription.id))
            .pipe(tap((previewResponse: PreviewForRenewalResponse) => {
                  previewResponse.planCode = body.planCode;
                this.setPreviewResponse(previewResponse, parentSubscription.renewalOpportunity.id);
                }
              )
            );
        })
      );
  }

  prepareAndUploadFileObservable(opportunity: number, poNumber: string, file: File, fileName?: string,
    documentType?: string
  ) {
    const reader = new FileReader();

      reader.onload  = () => {
        const base64String  = reader.result as string;
        const base64Content = base64String.split(',')[1];

        const body = {
          fileContent: base64Content, fileName: fileName, documentType: documentType, ...(poNumber && {poNumber})
        };

        this._renewalFlowService.uploadFile(opportunity, body).subscribe({
          next: (_) => {
            this.uploadState.next({
              status: ResponseStatusEnum.Success, loading: false
            });
          }, error: (err) => {
            console.error('Error uploading file:', err);
            this.uploadState.next({
              status: ResponseStatusEnum.Error, loading: false, errorMessage: err?.error?.errors[0].message
            });
          }
        });
      };

    reader.readAsDataURL(file);
  }

  public sendSupportMessageObservable(
    subscriptionId: number,
    message: string,
    firstName: string,
    lastName: string,
    phone: string
  ) {
    return this._renewalFlowService
      .sendSupportMessage(subscriptionId, message, firstName, lastName, phone);
  }

  public setCustomerInterest(subscriptionId: number, productCode: string[]) {
    this._renewalFlowService
      .setCustomerInterests(subscriptionId, productCode)
      .pipe(take(1))
      .subscribe(
        (_) => _,
        (_) => {
          this.translate.get('WIDGET.RENEWAL_STATE.ERRORS.INTEREST_NOT_PROCESSED').subscribe((res: string) => {
            this._notificationFacade.errorNotification(res);
          });
        }
      );
  }

  public setPreviewResponse(
    previewResponse: PreviewForRenewalResponse | null, opportunity: number
  ): void {
    // Directly assign the previewResponse without defaulting to an empty object
    this.state.previewResponse = previewResponse;
    this.saveState(opportunity);
  }

  public setPlanCode(planCode: string, opportunity: number) {
    this.state.planCode  = planCode;
    this.state.plan.name = planCode;
    this.saveState(opportunity);
  }

  cancelQuote(quoteId: string): Observable<Quote> {
    return this._renewalFlowService.cancelQuote(quoteId);
  }

  public getUploadedDocuments(opportunity: number) {
    return this._renewalFlowService.getUploadedDocuments(opportunity);
  }

  public deleteDocument(opportunity: number, documentId: number) {
    return this._renewalFlowService.deleteDocument(opportunity, documentId);
  }

  public closeOpportunity(opportunityId: number) {
    return this._renewalFlowService.closeOpportunity(opportunityId);
  }

  private getStepperState(opportunity: number): Observable<StepperState> {
    return this.uiStorageService.get<StepperState>(`${UiStorageKey.SUBSCRIPTION_RENEWAL}-${opportunity}`);
  }

  private setStepperState(
    body: StepperState, opportunity: number
  ): Observable<void> {
    return this.uiStorageService.put(`${UiStorageKey.SUBSCRIPTION_RENEWAL}-${opportunity}`, body);
  }
}
