import { Injectable } from '@angular/core';
import { NotificationState } from 'app/modules/widget/states/notification.state';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade {
  constructor(private notificationState: NotificationState) {}

  public successNotification = this.notificationState.showNotification.bind(
    this.notificationState
  );
  public errorNotification = this.notificationState.showErrorNotification.bind(
    this.notificationState
  );

  public successNotificationForModal = this.notificationState.showSuccessNotificationForModal.bind(
    this.notificationState);
  public errorNotificationForModal   = this.notificationState.showErrorNotificationForModal.bind(
    this.notificationState);



  public errorTemplateNotification =
    this.notificationState.showErrorTemplateNotification.bind(
      this.notificationState
    );
}
