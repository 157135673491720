import {JsonProperty} from 'json-object-mapper';
import {Family} from 'src/app/modules/widget/models/family.model';

export class Product {
  /** product family */
  @JsonProperty({type: Family}) public family: Family;
  /** product family variant */
  @JsonProperty({type: Family}) public variant: Family;

  constructor() {
    this.family  = null;
    this.variant = null;
  }
}
