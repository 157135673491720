import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Address, CustomerInformation } from 'libs/tsi/customer/src/lib/models';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, take } from 'rxjs/operators';
import { CustomerPartialState } from './customer.reducer';
import {
  getCustomerDataSavedSuccessfully,
  getShippingAddressSavedSuccessfully,
} from './customer.selectors';

import * as CustomerSelectors from './customer.selectors';
import * as CustomerActions from './customer.actions';

@Injectable()
export class CustomerFacade {
  /** Represents the error state as error message obtained from endpoint */
  public error$ = this.store.pipe(select(CustomerSelectors.getCustomerError));
  /** Whether there is any error thrown by endpoint or not. */
  public hasError$ = this.store.pipe(
    select(CustomerSelectors.hasCustomerError)
  );

  public errorWhileSavingData$ = this.store.pipe(
    select(CustomerSelectors.getSaveCustomerDataError)
  );
  /** Represents the customer information state */
  public customerInformation$ = this.store.pipe(
    select(CustomerSelectors.getSelected)
  );
  /** Whether the state is ready or not to use. */
  public isReady$ = this.store.pipe(
    select(CustomerSelectors.getCustomerLoaded)
  );
  /** Represents whether endpoint is retrying to fetch the data. */
  public isRetrying$ = this.store.pipe(
    select(CustomerSelectors.getCustomerLoading)
  );

  public isSuccessfullySaved$ = this.store.pipe(
    select(CustomerSelectors.isCustomerSavedSuccessfully)
  );

  constructor(
    private readonly store: Store<{ courseSection: CustomerPartialState }>
  ) {
    this.init();
  }

  public init(): void {
    this.store.dispatch(CustomerActions.init());
  }

  public save(
    customer: Partial<CustomerInformation>,
    showNotifications?: boolean
  ): void {
    this.store.dispatch(CustomerActions.resetSaveStatus());
    this.store.dispatch(CustomerActions.resetError());
    this.store.dispatch(
      CustomerActions.save({
        customer: customer,
        showNotification: showNotifications,
      })
    );
  }

  public saveShippingAddress(
    endUserId: string,
    shippingAddress: Address,
    showNotification?: boolean
  ): Observable<boolean> {
    this.store.dispatch(CustomerActions.resetError());
    this.store.dispatch(
      CustomerActions.saveShippingAddress({
        endUserId,
        shippingAddress,
        showNotification,
      })
    );

    return this.store.select(getShippingAddressSavedSuccessfully).pipe(
      filter((success) => success !== null),
      take(1)
    );
  }

  public saveCustomerData(
    customerId: string,
    customerData: Partial<CustomerInformation>,
    showNotification: boolean
  ): Observable<boolean> {
    this.store.dispatch(CustomerActions.resetError());
    this.store.dispatch(CustomerActions.resetCustomerDataSaveStatus());
    this.store.dispatch(
      CustomerActions.saveCustomerData({
        customerId,
        customerData,
        showNotification,
      })
    );

    return this.store.select(getCustomerDataSavedSuccessfully).pipe(
      filter((success) => success !== null),
      take(1)
    );
  }

  public saveAllCustomerInformation(
    shippingPayload: { id: string; shippingAddress: Address },
    customerPayload: Partial<CustomerInformation>,
    showNotifications?: boolean
  ): Observable<boolean[]> {
    return forkJoin([
      this.saveShippingAddress(
        shippingPayload.id,
        shippingPayload.shippingAddress,
        showNotifications
      ),
      this.saveCustomerData(
        customerPayload.id,
        customerPayload,
        showNotifications
      ),
    ]);
  }

  public reset(): void {
    this.store.dispatch(CustomerActions.reset());
  }
}
