import { JsonProperty } from 'json-object-mapper';

import { Address } from 'libs/tsi/customer/src/lib/models/address.model';
import { PrimaryContact } from 'libs/tsi/customer/src/lib/models/primary-contact.model';

export class CustomerInformation {
  /** holds customer id */
  @JsonProperty() public id?: string;
  /** checks if customer is individual */
  @JsonProperty() public individual?: boolean;
  /** holds company name */
  @JsonProperty() public companyName?: string;
  /** holds currency */
  @JsonProperty() public currency?: string;
  /** holds customer email */
  @JsonProperty() public email?: string;
  /** holds customer phone */
  @JsonProperty() public phone?: string;
  /** holds customer stripe link */
  @JsonProperty() public stripeCustomerAccountLink?: string;
  /** holds customer address */
  @JsonProperty({ type: Address }) public address?: Address;
  /** holds customer primary contact */
  @JsonProperty({ type: PrimaryContact })
  public primaryContact?: PrimaryContact;
  @JsonProperty() public taxIdentificationNumber?: string;

  @JsonProperty() public shippingAddress?: Address;

  constructor() {
    this.id = null;
    this.individual = null;
    this.companyName = null;
    this.currency = null;
    this.email = null;
    this.phone = null;
    this.stripeCustomerAccountLink = null;
    this.address = null;
    this.primaryContact = null;
    this.shippingAddress = null;
    this.taxIdentificationNumber = null;
  }

  get customerShippingAddress() {
    return this.shippingAddress || this.address;
  }
}
